import React, {useState , useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Dropdown, ButtonGroup, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {BsArrowLeft} from 'react-icons/bs';
import { IoMdHeart } from "react-icons/io";
import { TbCalendarTime } from "react-icons/tb";
import {FaEllipsisV , FaRegHeart } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import { toast} from "react-hot-toast";
import { getSinglePost , clearPostData} from '../../redux/actions/post.action';
import {valid} from '../../utils/validations';
import { changePostStatus } from '../../redux/actions/post.action';
import {decodedLoggedUser , logout , likePosts} from '../../helpers/auth';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import LiveCommentsAnimation from '../Comments/LiveCommentsAnimation';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import { AddAudio } from '../../redux/actions/audioPost.action';
import AudioSubcriber from '../AntMedia/AudioSubcriber';
import { clearPlayer } from '../../redux/actions/audioPost.action';



function AudioPostSingle() {
    const{slug} = useParams();  
    const [loading , setLoading] = useState(true);
    const [singlePost , setSinglePost] = useState('') ; 
    const [trackUrl , setTrackUrl] = useState(0)
    const [isBActive, setBActive] = useState("false");
    const [userId , setUserId] = useState('')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const apiResult = useSelector(state => state.post);
    const apiError = useSelector(state => state.post);
    const auth = useSelector(state => state.auth);
    const postStatus= useSelector(state => state.post.changeStatusSuccess);
    const postStatusError = useSelector(state => state.post.changeStatusError);
    const audioResult = useSelector(state => state.audioPost); 


    const handleShowToggle = () => {
        setBActive(!isBActive);
    };

    useEffect(()=>{
        if (auth.loggedIn) {
            let token = decodedLoggedUser();
            setUserId(token.aud)
        }
        setLoading(true)
        dispatch(getSinglePost(slug));
        return(()=>{
            dispatch(clearPostData());
        })
    },[]);

    useEffect(()=>{
        if(apiResult && apiResult?.singlePostSuccess){
            if(apiResult?.singlePostSuccess && apiResult?.singlePostSuccess.Post){
                setSinglePost(apiResult?.singlePostSuccess?.Post)
                setLoading(false)
                dispatch(AddAudio(apiResult?.singlePostSuccess?.Post))
            }
        }
    },[apiResult]);

    useEffect(()=>{
        if(apiError && apiError.singlePostError){
            setLoading(false);
        }
    },[apiError])


    const handleChangePostStatus = (postId , status) =>{
        const data = {postId:postId , status:status}
        setLoading(true)
        dispatch(clearPlayer())
        dispatch(changePostStatus(data))
    }

    useEffect(()=>{
        if(postStatus && postStatus.success){
            toast.success(postStatus.message, {
                position: 'top-center', 
            });
            if( postStatus.message.trim() === 'Post deleted'){
                navigate('/')
            }else{
                navigate('/drafts')
            }
        }
    },[postStatus])

    useEffect(()=>{
        if(postStatusError && postStatusError.message){
            toast.error(apiError.textPostError.message, {
                position: 'top-center', 
            });
        }
    },[postStatusError])

    const updateCommentList = () =>{
        dispatch(getSinglePost(slug));
    }


    const handleLikeLength = (post) =>{
        if(post){
            const postUserId = post.user_id

            const userFound = post.Likes.some(like => Number(like.user_id) === Number(postUserId))

            if(userFound){
                if(post.Likes && post.Likes.length > 0){
                    const length =  post.Likes.length-1 ;
                    if(length === 0){
                        return null
                    }else{
                        return length
                    }
                }
            }else{
                return post.Likes.length ;
            }
        }
    }

    const renderAudio = () =>{
        let track = [];
        if(valid(singlePost)){
            track.push(singlePost)
            if(valid(singlePost.media)){
                var totalTracks = singlePost.media.split(',') ;
                var trackNames = singlePost.name.split(',') ; 
                if(totalTracks && totalTracks.length > 0 ){
                    return (
                        <>
                           <div className='visual--desc audio--desc'>   
                                <ButtonGroup vertical className='mb-2 mt-0'>
                                    {
                                        totalTracks.map((item , ind)=>{
                                            return (
                                                <Button className={(trackUrl === item || trackUrl === ind) ? ' px-0' : 'px-0'} variant='link'> <b> { totalTracks.length > 1 ? ind+1. : ''}  </b> {trackNames[ind]}</Button>
                                            )
                                        })
                                    }
                                </ButtonGroup>
                                <div  className='mb-0' dangerouslySetInnerHTML={{ __html: singlePost.description }} />
                            </div>
                            <div className='visual--block mt-4'>
                                <img src={valid(singlePost.thumbnail) ? singlePost.thumbnail: '/images/thumb-400kb.jpg'}  alt={singlePost.name} />
                            </div>
                            <div className='audioLike--desc'>
                                <ListGroup>
                                    {(auth.loggedIn)&&
                                        <ListGroup.Item onClick = {()=>handleLike(singlePost)}>
                                            <span className='me-2'>
                                                {(singlePost?.Likes && singlePost.Likes.length > 0)&& handleLikeLength(singlePost)}
                                            </span>
                                            {singlePost?.Likes?.some(like => like?.user_id === userId) ? (
                                            <IoMdHeart />) : (<FaRegHeart /> 
                                            )}
                                        </ListGroup.Item>
                                    }
                                </ListGroup>
                            </div>
                         
                        </>
                    )
                }   
            }
        }
    }

    const handleCopy = async (slug) => {
        
        toast.success('Link copy to clipboard', {position: 'top-center'});
       
    };

    const handleUrl = (post)=>{
        let url = ''
        if (post.Postmeta.length) {
             url = `/edit/audio/album/${post.slug}`;
            return url
           
        }
        else {
            url = `/edit/audio/single/${post.slug}`;
            return url
        }
    }

    const handleLike = async (data) => {
        // if(Number(singlePost.user.id) !== Number(userId)){
            likePosts(userId, data.id)
        // }
    }
    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper visual--single--page'>
                    <Container>
                        {!loading ? 
                            valid(singlePost) ?
                                <>
                                    <Row className='justify-content-center'>
                                        <Col sm={12}>
                                            <Link className='back--btn' onClick={()=>navigate(-1)}><BsArrowLeft /> Back</Link>
                                        </Col>
                                        <Col sm={12} lg={8}>
                                            {(Number(userId) !== Number(singlePost.user.id) && singlePost.user.booked_schedulers && singlePost.user.booked_schedulers.length > 0)&&
                                                <div className='calendar--icon'>
                                                    <OverlayTrigger
                                                        key='top'
                                                        placement='top'
                                                        overlay={<Tooltip id={`tooltip-top`}>Schedule a Call</Tooltip>}
                                                        >
                                                        <Button variant='primary'><TbCalendarTime onClick={()=>navigate(`/book-appointments/${singlePost.user.id}`)}/></Button>
                                                    </OverlayTrigger>
                                                </div>
                                            }
                                            <div className='single--bg--col'>
                                                <div className='visual--user'>
                                                    <div className='visual--user--name Gr--streamDesc'>
                                                        <Link to={`/${singlePost.user.username}`}>
                                                            <img src={(singlePost.user && valid(singlePost.user.image)) ? singlePost.user.image : "/images/user.png"} alt='' />
                                                        </Link> 
                                                        <div class="Gr--streamUser">
                                                            <Link to={`/${singlePost.user.username}`}> <h5>{(singlePost.user && valid(singlePost.user.first))? singlePost.user.first : ''}</h5></Link>
                                                            <p>{moment(singlePost.createdAt).format('LL')}</p>
                                                        </div>
                                                        { (singlePost.user && userId == singlePost.user.id)&& 
                                                            <Dropdown className='edit--post--dropdown'>
                                                                <Dropdown.Toggle id="dropdown-basic">
                                                                    <FaEllipsisV />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                <Link className='dropdown-item' to={handleUrl(singlePost)}>Edit</Link>
                                                                    <Dropdown.Item>
                                                                        <CopyToClipboard text={window.location.href} onCopy={()=>{handleCopy()}}>
                                                                            <p>Share Link</p>
                                                                        </CopyToClipboard>   
                                                                    </Dropdown.Item>
                                                                    <Link className='dropdown-item' onClick={()=>handleChangePostStatus(singlePost.id , '2')}>Move to Drafts</Link>
                                                                    <Link className='dropdown-item' onClick={()=>handleChangePostStatus(singlePost.id , '0')}>Delete</Link>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        }    
                                                    </div>
                                                </div>
                                                
                                                {renderAudio()}
                                            </div>
                                            {singlePost.action === 'online' 
                                                ?   <AudioSubcriber streamId={singlePost.addPost}/>
                                                :   null
                                            }
                                            {singlePost.commenting && 
                                                <>
                                                    <LiveCommentsAnimation  updateComment={()=>updateCommentList()} postId = {singlePost}/>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </>
                            :   <h2 className="mt-5 text-center">Posts Not Found</h2>      
                        :
                            <Col sm={12}>
                                <div className='text-center spinner--loader'></div>
                            </Col>              
                        }       
                    </Container>
                </div>
            </div>
        </>
    );
}

export default AudioPostSingle;