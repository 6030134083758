import React from 'react'
import Scheduler from './Scheduler'
import {Button} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';


function MeetingSchudler() {
  const navigate = useNavigate();
  return (
    <div className='p-5 container'>
      <Button variant="primary" onClick={()=>navigate(-1)}>Back</Button>
        <Scheduler/>
    </div>
  )
}

export default MeetingSchudler