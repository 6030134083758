import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Modal, Form, Button, Col, Row, Dropdown, InputGroup } from 'react-bootstrap';
import { Calendar, Views, momentLocalizer, DateLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { MdAdd, MdEditCalendar } from "react-icons/md";
import DateCalender from 'react-calendar';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from "react-hot-toast";
import 'react-calendar/dist/Calendar.css'; // DateCalender CSS 
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {momentFormat,generateTimeSlots, parseStartTimeSlot, parseEndTimeSlot, isSameTime } from '../../utils/validations';
import EventWrapper from './EventWrapper';
import * as Auth from '../../helpers/auth';
import { createSchedule, getSchedules, updateSchedule, deleteSchedule } from '../../redux/actions/schedule.action';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';



const localizer = momentLocalizer(moment) // or globalizeLocalizer 
const getCurrentTimeIn24HourFormat = () => {
  const now = new Date();
  let hours = now.getHours(); // Get the hours in 24-hour format
  const minutes = now.getMinutes();

  const minutesStr = minutes < 10 ? '0' + minutes : minutes; // Add leading zero to minutes if necessary
  const hoursStr = hours < 10 ? '0' + hours : hours; // Add leading zero to hours if necessary

  return `${hoursStr}:${minutesStr}`;
};
// const check = ['undefined', undefined, null, 'null', '']; 
const Scheduler = (props) => {
  const customOption = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  }


  const eventParam = {
    title: '',
    start_date: '',
    start_time: '',
    date: '',
    end_time: '',
    days: -1,
    schedule_id: '',
    description: '',
    end_date: '',
    amount: '',
    schedule: '',
    availability:''
  }
  const scheduleOptions = [
    { value: 'daily', label: 'Daily Repeat' },
    { value: 'custom', label: 'Custom' },
  ]

  /** ----- Start / JEnd Date Picker ----  */
  const endDatePickerRef = useRef(null);
  const startDatePickerRef = useRef(null);
  const minimumDate = new Date();

  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [paymentMode, setPaymentMode] = useState(null);
  const [isStripeConnected, setConnected] = useState(false);
  /** ----- Start Calendar ----- */
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  /** ----- End Calendar ----- */
  const [showEndCalendar, setShowEndCalendar] = useState(false);

  /** ----- Past Schedules Not Editable ----- */
  const [isPastSchedule, setPastSchedule] = useState(false);

  const [classHourPrice, setClassHourPrice] = useState(0);

  /** ---- Show Event Modal ----- */
  const [showModal, setShowModal] = useState({ event: false, bookedUsers: false });
  /** ---- Show Custom Option Modal ---- */
  const [showCustomOptions, setShowCustomOptions] = useState(false);

  /** ----- All Events ----- */
  const [events, setEvents] = useState([]);

  /** ----- Single Event to Create ----- */
  const [event, setEvent] = useState(eventParam);

  /** ---- Time Options ---- */
  const [showTime, setShowTime] = useState(false);

  /** ----- Array Of Times for Start----- */
  const [startTimeOptions, setStartTimeOptions] = useState([]);

  /** ----- Array of Time for End  */
  const [endTimeOptions, setEndTimeOptions] = useState([]);

  /** ---- Is Used for Save Event Button ---- */
  const [isDisable, setIsDisable] = useState(false);

  const [selectedDay, setSelectedDay] = useState(null)
  const [ hideTime , setHideTime] = useState(true)


  /** ---- IS Editable ---- */
  const [isEdit, setIsEdit] = useState(false);
  const [allDay, setAllDay] = useState(false);
  const [showUpdateModel, setShowUpdateModel] = useState(false);
  const [disableAfterWeek, setDisableAfterWeek] = useState(null);
  const [errors, setErrors] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showUserModel, setShowUserModel] = useState(false);
  const [timeSlot, setTimeSlot] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [showRepeatOption, setShowRepeatOption] = useState(false);
  const [startTime, setStartTime] =useState(getCurrentTimeIn24HourFormat());
  const [endTime, setEndTime] =useState(getCurrentTimeIn24HourFormat());
  const [dailyRepeat , setDailyRepeat] = useState(false);
  const [timeError , setTimeError] = useState(false);

  
 




  // const [ dateCell, setDateCell] = useState([]);

  /** ----- Category Error ---- */
  // const [ categoryError, setCategoryError ] = useState('');
  /** ----------------------------try--[ Redux State ]------------------------------- */


  const scheduleState = useSelector(state => state.schedule);

  /** -------------------------------------------------------------------------- */
  const openEventModal = () => setShowModal({ event: true });

  const openEventUpdateModel = () => setShowUpdateModel(true);

  const openUserModel = () => setShowUserModel(true);



  /*************************************  Helping Functions  **************************************************/

  /***********    this function is using for combined the date and time  */

  const combineDateTime = (date, timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const combinedDateTime = new Date(date);
    combinedDateTime.setHours(hours);
    combinedDateTime.setMinutes(minutes);
    combinedDateTime.setSeconds(0);
    combinedDateTime.setMilliseconds(0);
    return combinedDateTime;
  };

const handleAllDay = (e) =>{
  let {checked } = e.target;
  setAllDay(checked)
} 


  

  /**************   using for get the currnet week left days according to start and end date  */


  const getAvailableDays = () => {
    if (!event.start_date || !event.end_date) return [];
  
    const days = new Set(); // Using a Set to ensure uniqueness
    let currentDate = new Date(event.start_date);
  
    while (currentDate <= new Date(event.end_date)) {
      const dayName = currentDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
      const dayOrder = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
      return dayOrder;
    }
  };



  /*******  using for show the list of time slots  */

  const showTimeList = () => {
    const timeSlots = generateTimeSlots();
    if (timeSlots && timeSlots.length > 0) {
      return (
        <>
          <Form.Control
            as="select"
            name="selectTimeSlot"
            onChange={(e) => handleModalInput(e)}
            value={event.selectTimeSlot}
            className='time--options'
          >
            <option value="">Start Time</option>
            {/* <option>09:00 to 17:00</option> */}
            {timeSlots.map((option, optionIndex) => {
              return <option
                key={optionIndex}
              // value={option} 
              >
                {option}
              </option>
            })}
          </Form.Control>
        </>
      )

    }
  }


  const handleStartTimeChange = (time) => {
    setStartTime(time);
    setTimeError(false)
  };

  const handleEndTimeChange = (time) => {
    setEndTime(time);
    setTimeError(false)
  };
  /******************************************************************************* */


  /****************************    Form handling functions  *************************/


  const handleModalInput = (e) => {
    let { name, value, checked } = e.target;
    const data = {};
    if (Object.keys(customOption).includes(name)) {
      data.availableDays = { ...event.availableDays }
      data.availableDays[name] = checked
    }
    if (name === 'schedule') {
      if (value === 'custom') {
        setShowCustomOptions(true)
        data.availableDays = { ...customOption }
        setAllDay(false)
      } else {
        data.availableDays = {monday: true,tuesday: true,wednesday: true,thursday: true,friday: true,saturday: true,sunday: true}
        setShowCustomOptions(false)
      }
    }
    if (name === 'availability') {
      data.availability = checked
    }
    if (name === 'selectTimeSlot') {
      if (value) {
        setTimeSlot(value)
        data.start_time = parseStartTimeSlot(value, event.start_date);
        data.end_time = parseEndTimeSlot(value, event.end_date);
      } else {
        setTimeSlot('')
      }
    }
    data[name] = value
    setEvent({ ...event, ...data });
  }


  const handleAmountChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[1-9]$|^\d+(\.\d{0,2})?$/;
    if (regex.test(value)) {
      setEvent(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else {
      setEvent(prevState => ({
        ...prevState,
        [name]: ''
      }));
    }
  };


  /** ------ Handle Date Picker ----- */
  const handleCalendarDate = (date, field) => {
    setIsDisable(false)
    let length = -1;
    if (field === 'start_date') {
      const endOfWeek = new Date(date);
      endOfWeek.setDate(endOfWeek.getDate() + 6);
      setDisableAfterWeek(endOfWeek);
      if (moment(date).isAfter(event.end_date, 'day')) {
        event.end_date = date
      }
      length = (moment(event.end_date).diff(moment(date), 'days') - 1);
    }

    if (field === 'endDate') {
      length = (moment(date).diff(moment(event.start_date), 'days') - 1);
    }

    setEvent({
      ...event,
      [field]: date,
      days: !isNaN(length)
        ? length
        : -1,
      schedule: length >= 0
        ? 'daily'
        : 'no_repeat'
    })
    handleHideCalendar();
  }




  const renderStartDatePicker = () => {
    return (
      <DateCalender
        ref={(ref) => startDatePickerRef.current = ref}
        onChange={(date) => handleCalendarDate(date, 'start_date')}
        minDate={minimumDate}
        view="month"      // Only display month view
        maxDetail="month"
      />
    )
  }


  const renderEndDatePicker = () => {
    return (
      <DateCalender
        ref={(ref) => endDatePickerRef.current = ref}
        onChange={(date) => handleCalendarDate(date, 'end_date')}
        minDate={event.start_date || minimumDate}
      />
    )
  }

  const validateTime = () => {
    let isValid = true ;
    if(!allDay){
      if (!startTime || !endTime){
        isValid = false ;
        setTimeError(true)
      } 

      if(startTime && endTime){

        const startHours = parseInt(startTime.split(':')[0], 10);
        const startMinutes = parseInt(startTime.split(':')[1], 10);

        const endHours = parseInt(endTime.split(':')[0], 10);
        const endMinutes = parseInt(endTime.split(':')[1], 10);

        if (startHours > endHours || (startHours === endHours && startMinutes >= endMinutes)) {
          isValid = false ;
          setTimeError(true)
        }
      }
    }
    return isValid
  };



  const saveEvent = () => {
    if(validateTime()){
      const check = ['' , undefined , null , [] , '' ,'undedefined' ,'null']
      const start = new Date(event.start_date);
      const end = new Date(event.end_date);
      var availDays = event.availableDays
      if(check.includes(availDays) ){
        availDays = {monday: true,tuesday: true,wednesday: true,thursday: true,friday: true,saturday: true,sunday: true}

      }
      const currentEvents = [...events];
      for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        const dayName = d.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
        if (availDays[dayName]) {
          if (allDay) {
            const startTimeDate = combineDateTime(d, '9:00');
            const endTimeDate = combineDateTime(d, '17:00');
            const conflictIndex = events.findIndex(schedule => {
              const existTimeMoment = moment(schedule.start_time, "ddd MMM DD YYYY HH:mm ZZ");
              const startTimeDateMoment = moment(startTimeDate, "ddd MMM DD YYYY HH:mm ZZ");
              return (
                existTimeMoment.isSame(startTimeDateMoment, 'minute')
              );
            });
            if (conflictIndex !== -1) {
              currentEvents[conflictIndex] = {
                user_id: userId,
                schedule_id: uuidv4(),
                title: event.title || '',
                amount: event.amount,
                date: d,
                start_time: startTimeDate.toString(),
                end_time: endTimeDate.toString(),
                description: event.description,
                start_date: start,
                end_date: end,
                schedule: event.schedule,
              };
            }else{
              currentEvents.push({
                user_id: userId,
                schedule_id: uuidv4(),
                title: event.title || '',
                amount: event.amount,
                date: d,
                start_time: startTimeDate.toString(),
                end_time: endTimeDate.toString(),
                description: event.description,
                start_date: start,
                end_date: end,
                schedule: event.schedule
              });
            } 
          } 
          else {
              const startTimeDate = combineDateTime(d, startTime);
              const endTimeDate = combineDateTime(d, endTime);
              const conflictIndex = events.findIndex(schedule => {
                const existTimeMoment = moment(schedule.start_time, "ddd MMM DD YYYY HH:mm ZZ");
                const startTimeDateMoment = moment(startTimeDate, "ddd MMM DD YYYY HH:mm ZZ");
                return (
                  existTimeMoment.isSame(startTimeDateMoment, 'minute')
                );
              });
              if (conflictIndex !== -1) {
                currentEvents[conflictIndex] = {
                  user_id: userId,
                  schedule_id: uuidv4(),
                  title: event.title || '',
                  amount: event.amount,
                  date: d,
                  start_time: startTimeDate.toString(),
                  end_time: endTimeDate.toString(),
                  description: event.description,
                  start_date: start,
                  end_date: end,
                  schedule: event.schedule,
                };
              }else{
                currentEvents.push({
                  user_id: userId,
                  schedule_id: uuidv4(),
                  title: event.title || '',
                  amount: event.amount,
                  date: d,
                  start_time: startTimeDate.toString(),
                  end_time: endTimeDate.toString(),
                  description: event.description,
                  start_date: start,
                  end_date: end,
                  schedule: event.schedule
                });
              } 
        }
      }
      }
      setEvents(currentEvents);
      dispatch(createSchedule({ schedules: currentEvents }))
      closeEventModal();
    }

  };


  const handleDelete = () => {
   const updateEvents =  events.filter(ev => ev.schedule_id !== event.schedule_id)
    setEvents(updateEvents);
    const data = { user_id: userId, schedule_id: event.schedule_id }
    dispatch(deleteSchedule(data))
    closeEventModal();
  }




  const handleDateLabelClick = (dateField) => {
    if (dateField === 'startDate') {
      setShowStartCalendar(!showStartCalendar);
      setShowEndCalendar(false);
    }

    if (dateField === 'endDate') {
      setShowEndCalendar(!showEndCalendar);
      setShowStartCalendar(false);
    }
  };


  const submitActions = () => {
    return (
      <>
        {isEdit
          ? updateActions()
          : <Button disabled={isDisable} onClick={(e) => isDisable ? null : saveEvent(e)}>Save</Button>
        }
      </>
    )
  }


  const handleSelectDay = (e, day) => {
    const updatedAvailableDays = {};
    getAvailableDays().forEach(d => {
      updatedAvailableDays[d] = false;
    });

    // Set the selected day
    updatedAvailableDays[day] = e.target.checked;

    // Update the state
    setSelectedDay(day);

  }


  const handleUpdateSchedule = () => {
    const start = new Date(event.start_date);
    const end = new Date(event.end_date);
    const currentEvents = [];
    if (currentEvents && currentEvents.length > 0) {
      if (currentEvents[0].start_time && currentEvents[0].end_time) {
        event.start_time = currentEvents[0].start_time.toString();
        event.end_time = currentEvents[0].end_time.toString();
      }
    }
    const isDuplicate = events.some(e =>
      e.id !== event.id &&
      isSameTime(e.start_time, event.start_time) &&
      isSameTime(e.end_time, event.end_time)
    );
    if (!isDuplicate) {
      const updatedEvents = events.map(e => {
        if (e.id === event.id) {
          return { ...e, ...event }; // Merge event properties
        }
        return e;
      });
      const data = { ...event, start_time: event.start_time.toString(), end_time: event.end_time.toString() }
      setEvents(updatedEvents);
      dispatch(updateSchedule(data))
      closeEventModal();
    } else {
      toast.success('Event with the same start and end time already exists.', {
        position: 'top-center',
      });
    }
    closeEventModal();
  }




  /************************************  end ***************************/


  /****************************    Handle Model functions ****************************************/



  const eventModal = () => {
    return (
      <Modal
        size='lg'
        centered
        show={showModal.event}
        onHide={closeEventModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {timeError && <small className="error error-massege text-danger"> Please enter a valid start and end time </small>}
          <Form>
            <Row>
              <Form.Group className="col-sm-6 form-group" controlId="exampleForm.ControlInput1">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  aria-label=""
                  type="text"
                  name="title"
                  value={event.title || ''}
                  onChange={(e) => handleModalInput(e)}
                />
              </Form.Group>
              <Form.Group className="col-sm-6 form-group" controlId="exampleForm.ControlInput1">
                <Form.Label>Total Amount</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    aria-label="Amount (to the nearest dollar)"
                    type="text"
                    name="amount"
                    value={event.amount}
                    onChange={(e) => handleAmountChange(e)}
                  />
                </InputGroup>
              </Form.Group>
            </Row>

            {/*  ------------------- Start Time  / End Time ------------------- */}
            <Col sm={12} className='p-0'>
              <h4>Select Date Range</h4>
              <Row>
                <Col sm={6}>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Group aria-label='startDatePicker' className='date__select form-group'>
                    <Form.Label
                      onClick={() => isEdit ? null : handleDateLabelClick('startDate')}
                      style={{ cursor: isEdit ? 'not-allowed' : 'pointer' }}
                    >
                    {momentFormat.formatWeekDate(event.start_date)}
                      <MdEditCalendar />
                    </Form.Label>
                    {showStartCalendar && renderStartDatePicker()}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Label>End Date</Form.Label>
                  {/*  -------- End Date Picker -------- */}
                  <Form.Group aria-label='endDatePicker' className='date__select form-group'>
                    <Form.Label
                      onClick={() => isEdit ? null : handleDateLabelClick('endDate')}
                      // className={isEdit ? 'cursor-blocked' : null}
                      style={{ cursor: isEdit ? 'not-allowed' : 'pointer' }}
                    >
                      {momentFormat.formatWeekDate(event.end_date)}
                      <MdEditCalendar />
                    </Form.Label>
                    {showEndCalendar && renderEndDatePicker()}
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Check
                      type="switch"
                      name="allDay" 
                      id = 'allDay'
                      label="All day"
                      checked={allDay}
                      onChange={(e) => handleAllDay(e)}
                    >
                    </Form.Check>
                  </Form.Group>
                </Col>
                {(!allDay)&&
                  <>
                    <Col sm={6}>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                      <Form.Label>Start Time</Form.Label>
                        <TimePicker 
                          value={startTime} 
                          id='start' 
                          format="h:mm a" 
                          onChange= {handleStartTimeChange}  
                          use12Hours
                      />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                      <Form.Label>End Time</Form.Label>
                        <TimePicker 
                          value={endTime} 
                          id='end' 
                          format="h:mm a" 
                          onChange= {handleEndTimeChange} 
                          use12Hours
                        />
                      </Form.Group>
                    </Col>
                  </>
                }
                
                {/*  ------ Select Schedule ------ */}
                {showRepeatOption &&
                  <Col sm={6}>
                    <Form.Label>Repeat</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Control
                        as="select"
                        name="schedule"
                        value={event.schedule}
                        onChange={(e) => handleModalInput(e)}
                      >
                        {scheduleOptions.map((op, opIndex) => (
                          <option
                            value={op.value}
                            key={opIndex}
                          >
                            {op.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                }
                <Col sm={12}>
                  {/*  ------------ Custom Options ---------- */}
                  {showCustomOptions &&
                    <Form.Group className='form-group'>
                      <Form.Label className='d-block'>Repeat Every</Form.Label>
                      {getAvailableDays().map((day, key) => (
                        <Form.Check
                          inline
                          key={key}
                          type="checkbox"
                          id={day}
                          label={day}
                          checked={!!event.availableDays[day]}
                          name={day}
                          onChange={(e) => handleModalInput(e)}
                          style={{ textTransform: 'capitalize' }}
                        />
                      ))}
                    </Form.Group>
                  }
                </Col>

                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Additional Information</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder='Any additional information?'
                      value={event.description}
                      name={'description'}
                      onChange={(e) => handleModalInput(e)}
                    >
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Modal.Footer className='px-0'>
              {!isPastSchedule && submitActions()}
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }


  const updateEventModal = () => {
    return (
      <Modal
        size='lg'
        centered
        show={showUpdateModel}
        onHide={closeEventModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Form.Group className="col-sm-6" controlId="exampleForm.ControlInput1">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  aria-label=""
                  type="text"
                  name="title"
                  value={event.title || ''}
                  onChange={(e) => handleModalInput(e)}
                />
              </Form.Group>
              <Form.Group className="col-sm-6" controlId="exampleForm.ControlInput1">
                <Form.Label>Total Amount</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    aria-label="Amount (to the nearest dollar)"
                    type="text"
                    name="amount"
                    value={event.amount}
                    onChange={(e) => handleAmountChange(e)}
                  />
                </InputGroup>
              </Form.Group>
            </Row>

            {/*  ------------------- Start Time  / End Time ------------------- */}
            <Col sm={12} className='p-0'>
              <Row>
                <Col sm={6}>
                  {/* ------- Start Date Picker -------- */}
                  <h5> Select Date Range </h5>
                  <br />
                  <Form.Label>Start Date</Form.Label>
                  <Form.Group aria-label='startDatePicker' className='date__select'>
                    <Form.Label
                      onClick={() => isEdit ? null : handleDateLabelClick('startDate')}
                      style={{ cursor: isEdit ? 'not-allowed' : 'pointer' }}
                    >
                      {momentFormat.formatWeekDate(event.start_date)}
                    </Form.Label>
                    {showStartCalendar && renderStartDatePicker()}
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Label>End Date</Form.Label>
                  {/*  -------- End Date Picker -------- */}
                  <Form.Group aria-label='endDatePicker' className='date__select'>
                    <Form.Label
                      onClick={() => isEdit ? null : handleDateLabelClick('endDate')}
                      // className={isEdit ? 'cursor-blocked' : null}
                      style={{ cursor: isEdit ? 'not-allowed' : 'pointer' }}
                    >
                      {momentFormat.formatWeekDate(event.end_date)}
                    </Form.Label>
                    {showEndCalendar && renderEndDatePicker()}
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            {/*  ------------ Custom Options ---------- */}
            <Form.Group>
              <Form.Label>
                Additional Information
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder='Any additional information?'
                value={event.description}
                name={'description'}
                onChange={(e) => handleModalInput(e)}
              >
              </Form.Control>
            </Form.Group>
            <Modal.Footer>
              {!isPastSchedule &&
                <Button disabled={isDisable} onClick={() => handleUpdateSchedule()}>Update</Button>
              }

              <Button variant='danger' onClick={handleDelete}>Delete</Button>

              {(isEdit && showTime) &&
                <Button
                  variant='dark'
                  className='ml-auto'
                  onClick={() => setShowModal({ ...showModal, bookedUsers: true })}
                  disabled={(event.bookedUsers && !event.bookedUsers.length)}
                >
                  {(event.bookedUsers && event.bookedUsers.length) ? `View ${event.bookedUsers.length} Users` : 'No Users Booked'}
                </Button>
              }
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }


  const userModel = () => {
    if (event && event.booked_scheduler) {
      return (
        <Modal
          size='lg'
          centered
          show={showUserModel}
          onHide={closeEventModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <h3> Schedule Booked by : {event.booked_scheduler.user.first || 'NA'}</h3>
            </div>
            <div>
              <h3> Meeting start Time  : {moment(event.start_time).format('MMMM Do YYYY, h:mm:ss a')}  </h3>
            </div>
            <div>
              <h3> Meeting end Time  :  {moment(event.end_time).format('MMMM Do YYYY, h:mm:ss a')} </h3>
            </div>
            <div>
              <h3>  User Email :  {event.booked_scheduler.user.email || 'NA'} </h3>
            </div>
          </Modal.Body>
        </Modal>
      )
    }

  }



  const closeEventModal = () => {
    setShowModal({ event: false, bookedUsers: false })
    setShowTime(false);
    setShowStartCalendar(false);
    setShowEndCalendar(false);
    setStartTimeOptions([]);
    setEndTimeOptions([]);
    setIsDisable(false);
    setIsEdit(false);
    setShowCustomOptions(false);
    setPastSchedule(false);
    setEvent({ ...eventParam });
    setShowUpdateModel(false);
    setShowUserModel(false);
    setStartTime(getCurrentTimeIn24HourFormat());
    setEndTime(getCurrentTimeIn24HourFormat());
    setTimeError(false)
    setAllDay(false)
  };



  /**************************************  end   *******************************************/


  /*********************  handle calender funcrions  **********************************************/


  const handleSelectEvent = useCallback(
    (e) => {
      if (moment().isAfter(moment(e.start), 'day')) {
        // Dates after maxDate are not selectable
        setPastSchedule(true);
        return;
      }
      setIsEdit(true)
      let data = {}
      data.availableDays = { ...customOption }
      if (e.start_time) {
        const startDay = e.start_time.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
        data.availableDays[startDay] = true;

        setSelectedDay(startDay)
      }
      if (e.schedule === 'custom') {
        setShowCustomOptions(true)
      }
      setEvent({
        ...e,
        ...data
      });

      if (e.booked_scheduler && e.booking_id) {
        openUserModel();
      } else {
        openEventUpdateModel();
      }
    },
    []
  )


  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: '#4b3f78', // Default color for non-booked events
      color: '#ffffff',
      borderRadius: '5px',
      border: 'none'
    };

    if (event.booking_id) {
      style.backgroundColor = '#8c49a3'; // Color for booked events
    }

    return {
      style: style
    };
  };



  /*****************   use for update the schedule get the alrady scheduled values  *****************/


  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      /** ----- Blocks to select past date from bookintg ---- */
      if (moment().isAfter(start, 'day')) {
        return;
      }
      let offlineMode = (paymentMode !== 'online' || !isStripeConnected);
      const length = parseInt((moment(end).diff(moment(start), 'days')) - 2);
      const data = {
        start_date: start,
        end_date: momentFormat.correctDate(end),
        days: length,
        schedule: (length >= 0) ? 'daily' : 'no_repeat',
        allowed_payment: offlineMode ? 'offline' : 'online'
      };
      setEvent(data);
      openEventModal();
    },
    [event] // Dependency added to ensure the correct events array is used
  );



  const handleOnClickSchedule = () => {
    let _startDate = moment().startOf('day').toDate()
    let _endDate = moment().add(1, 'd').startOf('day').toDate()

    handleSelectSlot({ start: _startDate, end: _endDate });
  }


  const handleHideCalendar = () => {
    setShowStartCalendar(false);
    setShowEndCalendar(false);
  }



  /************************************************************************************************ */



  /*************     update schedule functions  **********************************/



  const updatingSchedules = (type) => {
    var data = {
      service: typeof event.service === 'object' ? event.service._id : event.service,
      title: event.title || '',
      description: event.description,
      allDay: event.allDay,
    }

    if (!event.allDay) {
      data.start = momentFormat.combineDateTime(event.start_date, event.start_time)
      data.end = momentFormat.combineDateTime(event.end_date, event.end_time)
    }
    // also add fields in the update api in backend 
    // dispatch(updateSchedule(type === 'current' ? event._id : event.scheduleId, data, type));
    if (type === 'all') {
      toast.info('Update is in progress')
    }
    closeEventModal()
  }



  const updateActions = () => {
    return (
      event && event.schedule === 'no_repeat'
        ? <Button onClick={() => isDisable ? null : updatingSchedules('current')} disabled={isDisable}>Update</Button>
        :
        <Dropdown className='d-inline-flex'>
          <Dropdown.Toggle
            variant='primary'
            id="dropdown-basic"
            disabled={isDisable}
          >
            Update
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => isDisable ? null : updatingSchedules('current')} disabled={isDisable} >Current</Dropdown.Item>
            <Dropdown.Item onClick={() => isDisable ? null : updatingSchedules('all')} disabled={isDisable} >All</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
    )
  }


  /******************************************************************************************************************************************************************* */


  /***********************************  component Life cycle Method ***************************************/

  const getLoggedUser = async () => {
    const userData = Auth.decodedLoggedUser();
    setUserId(Number(userData.aud))
  }



  const handleGetSchedules = () => {
    const userData = Auth.decodedLoggedUser();
    const data = { userId: Number(userData.aud) };
    dispatch(getSchedules(data))
  }



  useEffect(() => {
    getLoggedUser()
    handleGetSchedules()
  }, [])


  useEffect(() => {
    if (scheduleState.error) {
      toast.error(scheduleState.error.message);
      setLoader(false);
    }
    if (!scheduleState.error) {
      if (scheduleState.schedules && scheduleState.schedules.success) {
        if (scheduleState.schedules.schedules && scheduleState.schedules.schedules.length > 0) {
          const convertedSchedules = scheduleState.schedules.schedules.map(schedule => ({
            ...schedule,
            start_time: new Date(schedule.start_time.replace(' ', 'T')),
            end_time: new Date(schedule.end_time.replace(' ', 'T'))
          }));
          setEvents(convertedSchedules);
        }
      }
      if (scheduleState.deleted && scheduleState.deleted.success) {
        handleGetSchedules();
      }
      if (scheduleState.scheduleUpdated && scheduleState.scheduleUpdated.success) {
        handleGetSchedules();
      }
      if (scheduleState.scheduleCreated && scheduleState.scheduleCreated.success) {
        handleGetSchedules();
      }

    }
  }, [scheduleState]);



  useEffect(() => {
    const hasStartTime = !event.start_time;
    const hasEndTime = !event.end_time;
    const hasNoService = !event.amount;
    const hasNoTitle = !event.title || '';
    const hasNoSlot = event.availability === 'custom'  && !timeSlot;
    const hasNoValidDays = event.schedule === 'custom' && !Object.values(event.availableDays).some(day => day);
    setIsDisable(hasNoService || hasNoTitle || hasNoSlot || hasNoValidDays);
    if (event.start_date && event.end_date) {
      const startDate = new Date(event.start_date);
      const endDate = new Date(event.end_date);
      const diffInTime = endDate.getTime() - startDate.getTime();
      const diffInDays = diffInTime / (1000 * 3600 * 24);
      setShowRepeatOption(diffInDays > 7);
    }
  }, [event]);


 






  /**********************************************************************************************************/




  /********************************************  jsx ( design part start ) ***********************************/


  return (

    <>
      <h3 className='d-flex mt-3'>Meeting Scheduler <Button className="schedule--add--btn ms-auto" onClick={() => handleOnClickSchedule()}><MdAdd /> Schedule Service</Button></h3>

      <Calendar
        events={events.map((evt, evtIndex) => { return { ...evt, start: moment(evt.start_time).toDate(), end: moment(evt.end_time).toDate() } })}
        startAccessor="start"
        endAccessor="end"
        views={['month','day', 'week',]}
        defaultView={Views.WEEK}
        localizer={localizer}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        components={{
          dateCellWrapper: EventWrapper,
        }}
        selectable
        eventPropGetter={eventStyleGetter}
      />

      {showModal.event && eventModal()}
      {showUpdateModel && updateEventModal()}
      {showUserModel && userModel()}

    </>
  )



}



Scheduler.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
}

export default Scheduler;

