
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Button, Alert, Modal } from "react-bootstrap";
import moment from 'moment';
import momentTz from 'moment-timezone'
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import Calendar from 'react-calendar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import { getSchedules, getAvailableTimeSlots, bookSchedule } from "../../redux/actions/schedule.action";
import * as Auth from '../../helpers/auth';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

function BookingModel() {

    /************************  Routing / redux  **********************************/
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const minimumDate = new Date();
    /*******************************************************************************/

    /***********************  Intial State  ****************************************/
    const [loader, setLoader] = useState(false);
    const [events, setEvents] = useState([]);
    const [bookEvent, setBookEvent] = useState('');
    const [timeSlote, setTimeSlote] = useState([]);
    const [ showError , setShowError] = useState(false);
    const [loading , setLoading] = useState(false);
    const [amount, setAmount] = useState('')
    const [bookSlote, setBookSlote] = useState({ start: '', end: '' });
    const [selectedDate, setSelectedDate] = useState('');
    const [userId, setUserId] = useState(null);
    const [userName, setUserName] = useState('');

    /***********************************************************************************/

    const allFieldsFilled = selectedDate !== '' && bookSlote.start.trim() !== '' && bookSlote.end.trim() !== '';

    /*********************************   Reducer States  *********************************/

    const scheduleState = useSelector(state => state.schedule);

    /******************************************    handling component  mounting  ****************************************************/

    useEffect(() => {
        handleGetSchedules(id);
        getLoggedUser();
    }, [id])

    useEffect(() => {
        if (scheduleState.error) {
            toast.error(scheduleState.error.message);
            setLoader(false);
        }
        if (!scheduleState.error) {
            if (scheduleState.schedules && scheduleState.schedules.success) {
                if (scheduleState.schedules.schedules && scheduleState.schedules.schedules.length > 0) {
                    const filteredSchedules = scheduleState.schedules.schedules.filter(schedule => schedule.booked_scheduler === null);
                    const convertedSchedules = filteredSchedules.map(schedule => ({
                        ...schedule,
                        start_time: new Date(schedule.start_time),
                        end_time: new Date(schedule.end_time),
                    }));

                    setEvents(convertedSchedules);
                }else{
                    setShowError(true)
                }
            }

            if (scheduleState.timeSlots && scheduleState.timeSlots.success) {
                if (scheduleState.timeSlots.schedules && scheduleState.timeSlots.schedules.length > 0) {
                    setTimeSlote(scheduleState.timeSlots.schedules)
                }
            }
        }


    }, [scheduleState]);

    /********************************************************  Helping functions   ********************************************************************************/

    // const isDayAvailable = ({ date }) =>.some((availableDay) => moment(date).isSame(moment(availableDay.date).startOf('day').toDate()));

    const showTimeList = () => {
        if (timeSlote && timeSlote.length > 0) {
            return (
                <Form.Control
                    as="select"
                    name="selectTimeSlot"
                    onChange={(e) => handleModalInput(e)}
                    value={timeSlote.selectTimeSlot}
                    className='time--options'
                >
                    <option value="">Select slot </option>
                    {timeSlote.map((option, optionIndex) => {
                        return <option
                            key={optionIndex}
                            value={optionIndex}
                        >
                            {`${option.start} to ${option.end}`}
                        </option>
                    })}
                </Form.Control>
            )
        }
    }


    const showAmount = () => {
        const check = ['', null, 'undefined', undefined, 'null']
        if (!check.includes(amount)) {
            return (
                <>
                    <h3 className="mb-4"><small>Meeting charges</small> {amount}</h3>
                </>
            )
        }
    }


    const handleModalInput = (e) => {
        let { value } = e.target;
        const slotIndex = timeSlote[value];
        const date = { start: slotIndex.start, end: slotIndex.end }
        setBookSlote(date)
        const combinedDate = combineDateTime(selectedDate, date.start, date.end)
        const event = getScheduleId(combinedDate.startDateTime)
        if (event) {
            setAmount(event.amount)
        }
    }

    const isAvailable = ({ date }) => {
        if (events && events.length > 0) {
            const enabledDates = events.map(entry => moment(entry.start_time).startOf('day').format('YYYY-MM-DD'));
            const formattedDate = moment(date).startOf('day').format('YYYY-MM-DD');
            return !enabledDates.includes(formattedDate);
        }
    }

    const stripSeconds = (date) => {
        const newDate = new Date(date);
        newDate.setSeconds(0, 0);
        return newDate;
    };

    const getScheduleId = (date) => {
        const CDate = stripSeconds(new Date(date));
        const matchingEntry = events.find(entry => {
            const StartTime = stripSeconds(new Date(entry.start_time));
            return StartTime.getTime() === CDate.getTime();;
        });
        return matchingEntry;
    }

    const combineDateTime = (dateString, startTime, endTime) => {
        // Parse the base date string
        const baseDate = new Date(dateString);

        // Extract hours and minutes from the start time
        const [startHours, startMinutes] = startTime.split(":").map(Number);
        // Create a new date object for the start time
        const startDate = new Date(baseDate);
        startDate.setHours(startHours, startMinutes, 0);  // Set hours and minutes, and reset seconds

        // Extract hours and minutes from the end time
        const [endHours, endMinutes] = endTime.split(":").map(Number);
        // Create a new date object for the end time
        const endDate = new Date(baseDate);
        endDate.setHours(endHours, endMinutes, 0);  // Set hours and minutes, and reset seconds

        return {
            startDateTime: startDate.toString(),
            endDateTime: endDate.toString()
        };
    }

    const getLoggedUser = async () => {
        const userData = Auth.decodedLoggedUser();
        setUserId(Number(userData.aud))
        setUserName(userData.firstName)
    }

    /**************************************  Api's call functions  ****************************************************************/

    const handleGetSchedules = (id) => {
        const data = { userId: Number(id) };
        dispatch(getSchedules(data))
    }

    const handleBookSchedule = () => {
        const date = combineDateTime(selectedDate, bookSlote.start, bookSlote.end)
        const event = getScheduleId(date.startDateTime)
        const data = {
            user_id: userId,
            schedule_id: event.schedule_id,
            booker_name: userName
        }
        setLoader(true)
        dispatch(bookSchedule(data))
    }

    /******************************************************************************************************************************/

    /*************************************************   calendar handling function  ***********************************************/

    const handleDayClick = (value) => {
        // const dateInUTC = moment(localDate).utc().format(); 
        setSelectedDate(value);
        const date = value.toString();
        const data = { start_time: date, userId: Number(id) }
        dispatch(getAvailableTimeSlots(data))
    }

    return (
        <>
            <div className="main-wraper-service-book">
                <>
                    <p className="mb-1">Select Date To Book Appointment</p>
                    <Calendar onChange={handleDayClick} minDate={minimumDate} tileDisabled={isAvailable} />

                    <div className="mt-3 mb-3">
                        <Form.Group className="form-group">
                            <p className="mb-1">Select Your Suitable Time Slot (<small>Time slots are in 24 hour format</small>)</p>
                            {showTimeList()}
                        </Form.Group>        
                        {showAmount()}
                        <Button onClick={() => handleBookSchedule()} disabled={!allFieldsFilled}>{loader ? 'Please Wait' : 'Book Now' } </Button>
                    </div>
                </>
            </div>
        </>
    )
}

export default BookingModel