import React, {useState , useEffect , useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, ListGroup, Dropdown, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { IoMdHeart } from "react-icons/io";
import {BsArrowLeft} from 'react-icons/bs';
import {FaEllipsisV , FaRegHeart} from 'react-icons/fa';
import { TbCalendarTime } from "react-icons/tb";
import moment from 'moment';
import { toast, Toaster } from "react-hot-toast";
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getSinglePost, clearPostData } from '../../redux/actions/post.action';
import './videoSingleStyle.css';
import '../../App.css';
import { checkHTML , convertToPlainText , valid} from '../../utils/validations';
import LiveCommentsAnimation from '../Comments/LiveCommentsAnimation';
import {decodedLoggedUser , logout , likePosts} from '../../helpers/auth';
import { changePostStatus } from '../../redux/actions/post.action';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AddVideoPlayer , clearVideoPlayer , addVideoTimeStamp} from '../../redux/actions/videoPost.action';




function VideoSingleMain() {
    const{slug} = useParams();  
    const [loading , setLoading] = useState(true);
    const [singlePost , setSinglePost] = useState('')
    const [isMobile , setIsMobile] = useState(false);
    const [userId , setUserId] = useState('')
    const [isBActive, setBActive] = useState("false");
    const [isActive, setActive] = useState("false");
    const [postError , setPostError] = useState(false)
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.post);
    const apiError = useSelector(state => state.post);
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();
    const postStatus = useSelector(state => state.post.changeStatusSuccess);
    const videoRef = useRef(null);
    
    
  

    
    useEffect(()=>{
        if (auth.loggedIn) {
            let token = decodedLoggedUser();
            setUserId(token.aud)
        }
        dispatch(getSinglePost(slug));
        dispatch(clearVideoPlayer())
    },[slug]);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
          setIsMobile(true);
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
        return () => {
            dispatch(clearPostData());
        };
    }, []);

    
    useEffect(()=>{
        if(postStatus && postStatus.success){
            if( postStatus.message.trim() === 'Post deleted'){
                navigate('/')
            }else{
                navigate('/drafts')
            }
        }
    },[postStatus])

    useEffect(()=>{
        if(apiResult && apiResult?.singlePostSuccess){
            if(apiResult.singlePostSuccess.Post){
                setSinglePost(apiResult?.singlePostSuccess?.Post)
                dispatch(AddVideoPlayer(apiResult?.singlePostSuccess?.Post))
                setLoading(false)
            }
        }
    },[apiResult]);
   
    useEffect(()=>{
        if(apiError && apiError.singlePostError){
            setLoading(false)
            if(apiError.singlePostError.message){
                setPostError(apiError.singlePostError.message)
            }
        }
    },[apiError])

    
    const handleToggle = () => {
        setActive(!isActive);
    };

    
    const handleShowToggle = () => {
        setBActive(!isBActive);
        setActive(!isActive);
    };

    const  handleLike = async (data) => {
        // if(Number(singlePost.user.id) !== Number(userId)){
            likePosts(userId, data.id)
        // }
    }
    const updateCommentList = () =>{
        dispatch(getSinglePost(slug));
    }

    const renderUserAction = ()=>{
        if (singlePost.user && singlePost.user.id == userId){
            return (
                <Dropdown className='edit--post--dropdown'>
                    <Dropdown.Toggle id="dropdown-basic">
                        <FaEllipsisV />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Link className='dropdown-item' to={`/edit-video/${singlePost.slug}`}>Edit</Link>
                        <Dropdown.Item>
                            <CopyToClipboard text={window.location.href} onCopy={()=>{handleCopy()}}>
                                <p>Share Link</p>
                                </CopyToClipboard>   
                        </Dropdown.Item>
                        <Dropdown.Item onClick={()=>handleChangePostStatus(singlePost.id,'2')}>Move to Drafts</Dropdown.Item>
                        <Dropdown.Item onClick={()=>handleChangePostStatus(singlePost.id,'0')}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
        }
    }

    const handleCopy = () => {
        toast.success('Link copy to clipboard', {position: 'top-center'});
    };


    const handleChangePostStatus = async(postId , status) =>{
        const data = {postId:postId , status:status}
        dispatch(clearVideoPlayer())
        dispatch(changePostStatus(data))
       
    }

    const handleTimeUpdate = () => {
        const currentTime = videoRef.current.currentTime;
        const data = {postId :singlePost.id , time:currentTime}
        dispatch(addVideoTimeStamp(data))
    };


    const handleLikeLength = (post) =>{
        if(post){
            const postUserId = post.user_id

            const userFound = post.Likes.some(like => Number(like.user_id) === Number(postUserId))

            if(userFound){
                if(post.Likes && post.Likes.length > 0){
                    const length =  post.Likes.length-1 ;
                    if(length === 0){
                        return null
                    }else{
                        return length
                    }
                }
            }else{
                return post.Likes.length ;
            }
        }
    }
    return (

        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper video--single--page'>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <Link className='back--btn' onClick={()=>navigate(-1)}><BsArrowLeft />Back</Link>
                            </Col>
                            {!loading ? 
                                valid(singlePost) ?
                                    <>
                                        <Col sm={12} lg={8} className=' mx-auto'>
                                            {(Number(userId) !== Number(singlePost.user.id) && singlePost.user.booked_schedulers && singlePost.user.booked_schedulers.length > 0)&&
                                                <div className='calendar--icon'>
                                                    <OverlayTrigger
                                                        key='top'
                                                        placement='top'
                                                        overlay={<Tooltip id={`tooltip-top`}>Schedule a Call</Tooltip>}
                                                        >
                                                        <Button variant='primary'><TbCalendarTime onClick={()=>navigate(`/book-appointments/${singlePost.user.id}`)}/></Button>
                                                    </OverlayTrigger>
                                                </div>
                                            }
                                            <div className='single--bg--col'>
                                                <div className='video--user Gr--streamDesc'>
                                                    <Link to={`/${singlePost.user.username}`}>
                                                        <img src={singlePost.user.image ||'/images/user.png'}  alt={singlePost.user.first} />
                                                    </Link>    
                                                    <div className='video--user--namee Gr--streamUser'>
                                                        <Link  to={`/${singlePost.user.username}`}>
                                                            <h2>{(singlePost.user && valid(singlePost.user.first))? singlePost.user.first : ''}</h2>
                                                        </Link>
                                                        <p>{moment(singlePost.createdAt).format('LL')}</p>
                                                    </div>
                                                    {renderUserAction()}
                                                </div>
                                                <div className='videoSingle--desc'>   
                                                    <h2 className='mt-3'>{checkHTML(singlePost.name) ? convertToPlainText(singlePost.name) : singlePost.name}</h2>
                                                    {checkHTML(singlePost.description) ? 
                                                        <div className='mb-0 text--white' dangerouslySetInnerHTML={{ __html: singlePost.description }} />
                                                        :
                                                        <p className='mb-0 text-light'>{singlePost.description}</p>
                                                    }
                                                </div>
                                                <div className='user--video'>
                                                    <video  ref={videoRef} preload="auto"  src={singlePost.media}  poster={singlePost.thumbnail || ''}  controls autoPlay={!isMobile}  onTimeUpdate={handleTimeUpdate}/>
                                                </div>
                                                <div className='videoSingle--likeBtn'>
                                                    <ListGroup>
                                                    {(auth.loggedIn)&&
                                                        <ListGroup.Item onClick = {()=>handleLike(singlePost)}>
                                                            {(singlePost.Likes && singlePost.Likes.length > 0) && <small className='me-2'>{handleLikeLength(singlePost)}</small>}
                                                            {singlePost?.Likes?.some(like => like?.user_id === userId) ? (
                                                            <IoMdHeart />) : (<FaRegHeart /> )}
                                                        </ListGroup.Item>
                                                    }
                                                    </ListGroup> 
                                                </div>
                                               
                                            </div>
                                        </Col>
                                        <Col sm={12} lg={8} className='mt-4 mx-auto'>
                                            {singlePost?.commenting &&
                                                <LiveCommentsAnimation  updateComment={()=>updateCommentList()} postId = {singlePost}/>
                                            }
                                             
                                        </Col>
                                    </>
                                    
                                :   
                                    <Col sm={12}>
                                       <h2 className="mt-5 text-center">Posts Not Found</h2>
                                    </Col>    
                                :
                                <Col sm={12}>
                                    <div className='text-center spinner--loader'></div>
                                </Col>    
                            }    
                           
                        </Row>
                    </Container>
                </div>
                <Toaster/>
            </div>
        </>
    );
}

export default VideoSingleMain;