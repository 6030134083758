import jwt_decode from "jwt-decode";
import { setAuthorization } from "./api";
import io from 'socket.io-client';


//  socket = io();

/** ----- Initialized Socket ----- */

export const currentUser = isAuth();
const checkLoggedIn = () =>{
    const token = localStorage.getItem('accessToken');
    if(token){
        const user = jwt_decode(token)
        return (
            io(process.env.REACT_APP_API_HOST, {
                query: { _id: user.aud},
                transports: ['polling']
            }) 
        )
    }else{
        {}
    }

}

export const socket = checkLoggedIn();
    // currentUser ? 
    //     io(process.env.REACT_APP_API_HOST, {
    //         query: { _id: currentUser.aud},
    //         transports: ['polling']
    //     }) 
    // :   {}
       


export const likePosts = (userId, postId) => {
    if(currentUser){
        socket.emit('LikePost', { userId, postId });
    }
};
    
export const SendComment = (data) => {
    if(currentUser){
        socket.emit('comment', data);
    }
};


export const joinRoom = (roomId) =>{
    if(currentUser){
        socket.emit('join_room', roomId);
    }
}

export const likeComments = (data) =>{
    if(currentUser){
        socket.emit('LikeUnlikeComments', data);
    }
}

export const deleteComment = (data) =>{
    if(currentUser){
        socket.emit('DeleteComment', data);
    }
}

export const getUserNotifications = (data)=>{
    if(currentUser){
        socket.emit('getNewNotication', data);
    }
}

export const removeNotification = (data)=>{
    if(currentUser){
        socket.emit('removeNotification',data)
    }
}


export const removeAllNotifications = (data)=>{
    if(currentUser){
        socket.emit('removeAllNotification',data)
    }
}

export const startLiveVideo = (data) =>{
    if(currentUser){
        socket.emit('create-live-video-post',data)
    }
}

export const startLiveAudio = (data) =>{
    if(currentUser){
        socket.emit('create-live-audio-post',data)
    }
}

export const startSaveChunks = (data)=>{
    socket.emit('save_chunks',data)
}

export const stopLiveVideo = (data)=>{
    if(currentUser){
        socket.emit('update-live-video-post',data)
    }
}

export const stopLiveAudio = (data)=>{
    if(currentUser){
        socket.emit('stopLiveAudio',data)
    }
}


export function getToken() {
    return localStorage.getItem('accessToken');
   
}

export function isAuth() {
    try {
      const token = localStorage.getItem('accessToken');
      if (token) {
        return jwt_decode(token);
      }
      return false;
    } catch (err) {
      return false;
    }
}



export function login(token, appId = '') {
    localStorage.setItem('accessToken', token);
    localStorage.setItem('appId', appId);
    setAuthorization();
    window.location.replace('/')
    return true;
}


export function logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('appId');
    setAuthorization();
        window.location.replace('/login')
    return true;
}


export function decodedLoggedUser(){
    const token = localStorage.getItem('accessToken');
    return  jwt_decode(token)
}



export function setRemember(user = {}) {
    localStorage.setItem('userRemember', JSON.stringify(user || isAuth()));
    return true;
}
  
export function removeRemember() {
    localStorage.removeItem('userRemember');
    return true;
}
  



const checkHTML = param => {
    return new RegExp(/(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/gm).test(param)
}



const changeCurrecy = (rates, from, to, price) => {
    if (from === to) {
        return Number(price)
    }
    else {
        return Number(((Number(price) * 100) * rates[to] * (1 / rates[from]) / 100).toFixed(2));
    }
}


const capitalizeFirstLetter = (string) => {
    if(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
}


export function  validURL(str){
    var res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
}
