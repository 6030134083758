import { get } from 'lodash';
import API from '../../helpers/api';
import * as auth from '../../helpers/auth';

import {
    CREATE_SCHEDULE,
    GET_SCHEDULE,
    CREATE_SCHEDULE_ERROR,
    GET_SCHEDULE_ERROR,
    UPDATE_SCHEDULE,
    UPDATE_SCHEDULE_ERROR,
    SCHEDULE_COMMON_ERROR , 
    DELETE_SCHEDULE_SUCCESS,
    DELETE_SCHEDULE_ERROR,
    GET_TIME_SLOTS,
    GET_TIME_SLOTS_ERROR , 
    BOOK_SCHEDULE,
    BOOK_SCHEDULE_ERROR,
} from "./types";

function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
      dispatch({
        type: SCHEDULE_COMMON_ERROR,
        payload: data.error.message,
      });
    } else if (data.status === 401) {   
      auth.logout()
    } else {
      dispatch({
        type: SCHEDULE_COMMON_ERROR,
        payload: data.message,
      });
    }
}


/*************************    using for create schedules  *****************************/

export const createSchedule = (payload) =>{
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('schedule', '/create' , payload ) ;
            if(response.data && response.data.success){
                await dispatch({ type: CREATE_SCHEDULE, payload: response.data});
            }else{
                await dispatch({ type: CREATE_SCHEDULE_ERROR, payload: response.data});
            }
        }catch(error){
            errorRequest(error, dispatch);
        }
    }
}


/***********************************************************************************************/


/********************************    using for get schedule by user id  *********************/

export const getSchedules = (payload)=>{
  
    return async (dispatch) => {
      try{
        const response = await API.apiGetByKey('schedule', '/schedules' , payload) ;
        if(response.data && response.data.success){
          await dispatch({ type: GET_SCHEDULE, payload: response.data});
        }else{
          await dispatch({ type: GET_SCHEDULE_ERROR, payload: response.data});
        }
      }catch(error){
        errorRequest(error, dispatch);
      }
    } 
}


/******************************************************************************************************/


/*****************************    using for update the schedule by using the schedule id  ***************/

export const updateSchedule = (payload)=>{
    return async (dispatch) => {
      try{
        const response = await API.apiPutUrl('schedule', '/edit/schedule' , payload) ;
        if(response.data && response.data.success){
          await dispatch({ type:  UPDATE_SCHEDULE, payload: response.data});
        }else{
          await dispatch({ type:  UPDATE_SCHEDULE_ERROR, payload: response.data});
        }
      }catch(error){
        errorRequest(error, dispatch);
      }
    } 

}


/**********************************************************************************************************/



/***************************************   using for delete the schedule by schedule id  */


export const deleteSchedule = (payload)=>{
    return async (dispatch) => {
      try{
        const response = await API.apiDelete('schedule', '/delete/schedule' , payload) ;
        if(response.data && response.data.success){
          await dispatch({ type: DELETE_SCHEDULE_SUCCESS, payload: response.data});
        }else{
          await dispatch({ type:  DELETE_SCHEDULE_ERROR, payload: response.data});
        }
      }catch(error){
        errorRequest(error, dispatch);
      }
    } 
}


export const getAvailableTimeSlots = (payload) =>{
  return async (dispatch) => {
    try{
      const response = await API.apiGetByKey('schedule', '/get_time_slots' , payload) ;
      if(response.data && response.data.success){
        await dispatch({ type: GET_TIME_SLOTS, payload: response.data});
      }else{
        await dispatch({ type: GET_TIME_SLOTS_ERROR, payload: response.data});
      }
    }catch(error){
      errorRequest(error, dispatch);
    }
  } 

}



export const bookSchedule = (payload) =>{
  return async (dispatch) => {
      try{
          const response = await API.apiPostUrl('schedule', '/book_schedule' , payload ) ;
          if(response.data && response.data.success){
              await dispatch({ type: BOOK_SCHEDULE, payload: response.data});
          }else{
              await dispatch({ type: BOOK_SCHEDULE_ERROR, payload: response.data});
          }
      }catch(error){
          errorRequest(error, dispatch);
      }
  }
}






