import React from 'react';
import { Container, Dropdown, Row, Col } from 'react-bootstrap';
import { FaEllipsisV, FaEye, FaPencilAlt } from 'react-icons/fa';
import { BiTrash } from 'react-icons/bi';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import './shopStyle.css';
import '../../App.css';
import { Link } from 'react-router-dom';

function ShopMain() {

    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper shop--Main--page'>
                    <Container>
                        <Row>
                            <Col sm={6} lg={4} xl={3} className='mb-4'>
                                <div className="Gr--singleStream">
                                    <Dropdown className='edit--post--dropdown'>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <FaEllipsisV />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Link className='dropdown-item' to="/marketplace"><FaPencilAlt /> Edit</Link>
                                            <Link className='dropdown-item' to="/marketplace"><BiTrash /> Delete</Link>
                                            <Link className='dropdown-item' to="/marketplace"><FaEye /> Show</Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <div className="Gr--streamImage">
                                        <Link to="/marketplace/product-shop">
                                            <img src="/images/1566289079327-thumbnail_0524.jpg" alt="..." />
                                        </Link>
                                    </div>
                                    <div className="Gr--streamUser">
                                        <h4><Link to="/marketplace/product-shop">regular audio single track test </Link></h4>
                                    </div>
                                    <div className="Gr--streamDesc">
                                        <Link to="/marketplace/product-shop">
                                            <img src="/images/default-video.jpg" alt="..." />
                                            <p>avika <span>12 April 2023</span></p>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} lg={4} xl={3} className='mb-4'>
                                <div className="Gr--singleStream">
                                    <div className="Gr--streamImage">
                                        <Link to="/marketplace/product-shop">
                                            <img src="/images/1566289079327-thumbnail_0524.jpg" alt="..." />
                                        </Link>
                                    </div>
                                    <div className="Gr--streamUser">
                                        <h4><Link to="/marketplace/product-shop">regular audio single track test </Link></h4>
                                    </div>
                                    <div className="Gr--streamDesc">
                                        <Link to="/marketplace/product-shop">
                                            <img src="/images/default-video.jpg" alt="..." />
                                            <p>Sarah <span>12 April 2023</span></p>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} lg={4} xl={3} className='mb-4'>
                                <div className="Gr--singleStream">
                                    <div className="Gr--streamImage">
                                        <Link to="/marketplace/product-shop">
                                            <img src="/images/1566289079327-thumbnail_0524.jpg" alt="..." />
                                        </Link>
                                    </div>
                                    <div className="Gr--streamUser">
                                        <h4><Link to="/marketplace/product-shop">regular audio single track test </Link></h4>
                                    </div>
                                    <div className="Gr--streamDesc">
                                        <Link to="/marketplace/product-shop">
                                            <img src="/images/default-video.jpg" alt="..." />
                                            <p>Danny <span>12 April 2023</span></p>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} lg={4} xl={3} className='mb-4'>
                                <div className="Gr--singleStream">
                                    <div className="Gr--streamImage">
                                        <Link to="/marketplace/product-shop">
                                            <img src="/images/1566289079327-thumbnail_0524.jpg" alt="..." />
                                        </Link>
                                    </div>
                                    <div className="Gr--streamUser">
                                        <h4><Link to="/marketplace/product-shop">regular audio single track test </Link></h4>
                                    </div>
                                    <div className="Gr--streamDesc">
                                        <Link to="/marketplace/product-shop">
                                            <img src="/images/default-video.jpg" alt="..." />
                                            <p>Danny <span>12 April 2023</span></p>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} lg={4} xl={3} className='mb-4'>
                                <div className="Gr--singleStream">
                                    <div className="Gr--streamImage">
                                        <Link to="/marketplace/product-shop">
                                            <img src="/images/1566289079327-thumbnail_0524.jpg" alt="..." />
                                        </Link>
                                    </div>
                                    <div className="Gr--streamUser">
                                        <h4><Link to="/marketplace/product-shop">regular audio single track test </Link></h4>
                                    </div>
                                    <div className="Gr--streamDesc">
                                        <Link to="/marketplace/product-shop">
                                            <img src="/images/default-video.jpg" alt="..." />
                                            <p>Sarah <span>12 April 2023</span></p>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ShopMain;