import React, { useRef , useEffect, useState } from "react";
import {useNavigate , useParams} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {generateVideoThumbnails} from "@rajesh896/video-thumbnails-generator";
import { Button, Row, Col, Form, Container , Spinner} from 'react-bootstrap';
import { FaCogs} from 'react-icons/fa';
import {IoClose} from 'react-icons/io5';
import { toast, Toaster } from "react-hot-toast";   
import { EditorState, convertToRaw, getDefaultKeyBinding ,ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PageHeader from "../Header/HeaderTop";
import { notValid, checkHTML , convertToPlainText } from "../../utils/validations";
import ImageCropper from "../Create/CreateTabs/imageCropper";
import { getSinglePost ,getBoardPostList } from "../../redux/actions/post.action";
import { uploadVideo , editVideoPost} from "../../redux/actions/videoPost.action";
import '../Shop/shopStyle.css';
import '../../App.css';
import SidebarMenu from "../Sidebar/SidebarNav";

function EditVideo() {
    const maxSize = 300 * 1024 * 1024 ;
    const{slug} = useParams();  
    const intialValue = {postId : '' , name:'' , media : '' ,thumbnail:'' ,  description: '',isStream: true,  commenting: true , board_name: '', board_id: '',};
    const [videoFields , setVideoFields] = useState(intialValue);
    const [loading , setLoading] = useState(true) ; 
    const [videoUploading , setVideoUploading] = useState(false);
    const [publishLoading , setPublishLoading] = useState(false);
    const [boardList , setBoardList] = useState('');
    const [draft , setDraft] =  useState(false);
    const [draftLoading , setDraftLoading] = useState(false);
    const [ isCropperOpen, setCropperOpen ] = useState(false);
    const [ imageForCrop, setImageForCrop ] = useState(null);   
    const [showEditor , setShowEditor] = useState(true);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [errors , setErrors] = useState(false);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.videoPost);
    const getPostResult = useSelector(state => state.post);

    useEffect(()=>{
        dispatch(getSinglePost(slug));
        dispatch(getBoardPostList())
    },[]);

    useEffect(()=>{
        if(getPostResult && getPostResult.singlePostSuccess){
            let copyVideoFields = {...videoFields};
            copyVideoFields.postId =  getPostResult.singlePostSuccess.Post.id ;
            copyVideoFields.name =  getPostResult.singlePostSuccess.Post.name || '';
            copyVideoFields.thumbnail = getPostResult.singlePostSuccess.Post.thumbnail || '';
            copyVideoFields.commenting = getPostResult.singlePostSuccess.Post.commenting;
            copyVideoFields.isStream = getPostResult.singlePostSuccess.Post.isStream || true ; 
            copyVideoFields.board_name = getPostResult.singlePostSuccess.Post.board_name || '';
            copyVideoFields.board_id  = getPostResult.singlePostSuccess.Post.board_id || '';
            copyVideoFields.media =  getPostResult.singlePostSuccess.Post.media || '';
            if(getPostResult.singlePostSuccess.Post.description){
                const blocksFromHtml = htmlToDraft(getPostResult.singlePostSuccess.Post.description);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState)
                copyVideoFields.description = getPostResult.singlePostSuccess.Post.description
            }

            setVideoFields(copyVideoFields)
        }
        if(getPostResult && getPostResult.boardPostList){
            setBoardList(getPostResult.boardPostList.boardPosts)
        }
        if(getPostResult && getPostResult.boardPostError){
            toast.error(getPostResult.boardPostError.message, {
                position: 'top-center', 
            });
        }
        if(getPostResult.singlePostError){
            toast.error(apiResult.textPostError, {
                position: 'top-center', 
            });
        }
        setLoading(false)

    },[getPostResult])

/***********************************  form handle functions  ********************************************************/

    const handleInput = (e) =>{
        const {value , name } = e.target ; 
        setVideoFields({...videoFields, [name]: value })
        setErrors({ ...errors, [name]: '' })
    }

    const handleCommenting = (e) =>{
        const copyVideoFields = {...videoFields} ;
        copyVideoFields.commenting = e.target.checked
        setVideoFields(copyVideoFields)
    }

    const selectBoard = () => {
        if(boardList && boardList.length > 0){
            return (
                <Form.Group className='form-group col-sm-12'>
                    <Form.Label>Realm</Form.Label>
                    <Form.Control value={videoFields.board_id || '' } onChange= {handleBoard} as="select">
                        <option selected>Select</option>
                        {boardList.map((item , idx)=>{
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
            )
        }
    }

    const handleBoard = (e)=>{
        let copyVideoFields = {...videoFields};
        copyVideoFields.board_id = e.target.value
        setVideoFields(copyVideoFields)

    }
/**********************    handle description  /////   text editor  function using draft.js for text editing        ******/

    const onEditorStateChange = (editorState) => {
        const copyVideoFields = {...videoFields} ;
        let errorMessages = {...errors};
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const description = draftToHtml(rawContentState);
        copyVideoFields.description = description
        errorMessages.description = '' ;
        setVideoFields(copyVideoFields)
        setErrors(errorMessages)
        setEditorState(editorState);
    };

    const myKeyBindingFn = event => {
        return getDefaultKeyBinding(event);
    }
/********************************************************************************************************************* */
/*****************   form validation  ***************************************/
    const validate = () =>{
        let isValid = true ;
        let errorMessages = {};
        let desc = checkHTML(videoFields.description) ? convertToPlainText(videoFields.description) : videoFields.description
        if (notValid(desc)) {
            errorMessages['description'] = 'Description is required'
            isValid = false;
        }
        if (notValid(videoFields.name)) {
            errorMessages['name'] = 'Title is required'
            isValid = false;
        }

        if(videoFields.media === ''){
            errorMessages['media'] = 'Please upload video '
            isValid = false;
        }
        if(videoFields.thumbnail === ''){
            errorMessages['thumbnail'] = 'Please upload thumbnail '
            isValid = false;
        }
        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;
    }

/******************************************************************************************************************** */
/********************************************* using for crop image  *********************************************/

const handleCloseCropper = () => {
    setImageForCrop(null)
    setCropperOpen(false)
}

const handeSaveCroppedImage = (dataURI) => {
    const imageFile = dataURItoBlob(dataURI);
    const copyVideoFields = {...videoFields}
    copyVideoFields['thumbnail'] = imageFile
    setVideoFields(copyVideoFields)
}

const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    
    return new Blob([ia], {type:mimeString});
}


const handleOpenCropper = () => {
    setCropperOpen(true)
    let data = {
        src: URL.createObjectURL(videoFields.thumbnail)
    }
    setImageForCrop(data)
}

/************************************************************************************************************** */

/**-------------  video or thumbnail handling  -------------------------- */

    const handleVideoChange = async(event) => {
        const copyVideoFields = {...videoFields} ;
        let errorMessages = {...errors};
        const file = event.target.files[0];
        if (file && file.type.includes('video/')) {
            if(file.size < maxSize){
                errorMessages.video = ''
                setErrors(errorMessages)
                getVideoUrl(file)
                const thumbnail = await generateThumbnail(file);
                copyVideoFields.thumbnail = thumbnail
                setVideoFields(copyVideoFields)
            }else{
                errorMessages.video = 'Video size exceeds the maximum limit of 300 MB';
                setErrors(errorMessages)
            }
        }else{
            errorMessages.video = 'Please select a valid video file (e.g., MP4, MOV).';
            setErrors(errorMessages)
        }
    };


    const generateThumbnail = async(file ) => {
        const copyVideoFields = {...videoFields} ;
        if (file) {
            var thumbnail = await generateVideoThumbnails(file , 1)
            const thumbnailImage =  dataURItoBlob(thumbnail[0])
            return thumbnailImage
        }
        
    };


    const getVideoUrl = (file) =>{
        setVideoUploading(true)
        const formData =  new FormData();
        formData.append('video' ,file)
        dispatch(uploadVideo(formData))
    }

    const handleRemove = () =>{
        let copyVideoFields = {...videoFields};
        copyVideoFields.media = '';
        setVideoFields(copyVideoFields)
    }

    const handleRemoveThumbnail = () =>{
        let copyVideoFields = {...videoFields};
        copyVideoFields.thumbnail = '';
        setVideoFields(copyVideoFields)
    }


    const handleCreateVideoPost = (draft)=>{
        const copyVideoFields = {...videoFields}
        if(validate()){
            if(draft){
                copyVideoFields.status = '2'
                setPublishLoading(false)
                setDraftLoading(true)
                setDraft(true)
            }else{
                setPublishLoading(true)
                setDraftLoading(false)
            }
            const formData = new FormData();
            for (const [key, value] of Object.entries(copyVideoFields)) {
                formData.append(key, value)
            }  
            dispatch(editVideoPost(formData)) ;
        }

    }

    useEffect(()=>{
        if(apiResult && apiResult.editVideo){
            setPublishLoading(true)
            setDraftLoading(false)
            setVideoUploading(false)
            toast.success(apiResult.editVideo.message, {
                position: 'top-center', 
            });
            if(draft){
                navigate('/drafts')
            }else{
                navigate('/video')
            }
            
        }    
        if(apiResult && apiResult.videoPostError){
            setPublishLoading(false)
            setVideoUploading(false)
            setDraftLoading(false)
            toast.error(apiResult.videoPostError.message, {
                position: 'top-center', 
            });
        }
        if(apiResult && apiResult.videoUrl){
            let copyVideoFields = {...videoFields}
            copyVideoFields.media = apiResult.videoUrl ; 
            setVideoFields(copyVideoFields);
            setVideoUploading(false)
        }
        setLoading(false)
        setDraftLoading(false)
    },[apiResult])

    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper create--shop--form'>
                    <Container>
                        <Row className='justify-content-center pt-3'>
                            {!loading 
                            
                                ?   <Col sm={12} lg={10}>
                                        <Form>
                                            <Row>
                                                {videoFields.media
                                                    ?
                                                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                            <div className="post--thumb--upload">
                                                                <video preload="auto" src={typeof videoFields.media === 'object' ? URL.createObjectURL(videoFields.media) : videoFields.media} controls/>
                                                                <span className="post--media--close" onClick = {handleRemove}><IoClose/></span>
                                                            </div>
                                                        </Form.Group>
                            
                                                    :    videoUploading ? (
                                                                <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                                    <div className="post--thumb--upload">
                                                                        <Spinner animation="border"/>
                                                                    </div>
                                                                </Form.Group>
                                                            )
                                                        :
                                                        <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                            <Form.Label>Add Video</Form.Label>
                                                            <input type='file' hidden id="upload--header-video"  accept="video/*"  onChange={handleVideoChange}/>
                                                            <label className='label--upload' htmlFor="upload--header-video">Choose File</label>
                                                            {errors.media ?
                                                                <span className="error error-massege text-danger">{errors.media}</span>
                                                                :  errors.video
                                                                ? 
                                                                    <span className="error error-massege text-danger">{errors.video}</span>
                                                                : ''
                                                            }
                                                        </Form.Group>
                                                }
                                                
                                                {videoFields.thumbnail 
                            
                                                ?
                                                    <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                        <div className="post--thumb--upload">
                                                            <img src={typeof videoFields.thumbnail === 'object' ? URL.createObjectURL(videoFields.thumbnail) : videoFields.thumbnail}/>
                                                            <span className="post--media--close" onClick = {handleRemoveThumbnail}><IoClose/></span>
                                                        </div>
                                                    </Form.Group>
                                                :
                                                    <Form.Group className='form-group col-sm-12 col-lg-6'>
                                                        <Form.Label>Add Thumbnail</Form.Label>
                                                        <input type='file' hidden id="upload--header-thumnail" />
                                                        <label className='label--upload' htmlFor="upload--header-thumnail">Choose File</label>
                                                        {errors.thumbnail && <span className="error error-massege text-danger">{errors.thumbnail}</span>}
                                                    </Form.Group>
                                                }    
                                                <Form.Group className='form-group col-sm-12'>
                                                    <Form.Label>Title</Form.Label>
                                                    <Form.Control type="text" name = 'name' value = {videoFields.name} onChange={handleInput} required />
                                                    {errors.name && <span className="error error-massege text-danger">{errors.name}</span>}
                                                </Form.Group>
                                                <Form.Group className='form-group col-sm-12'>
                                                    <span className='editor--tool' onClick = {()=>setShowEditor(!showEditor)}><FaCogs /></span>
                                                </Form.Group>
                                                <Form.Group className='form-group col-sm-12'>
                                                    <Editor
                                                        editorState={editorState}
                                                        wrapperClassName="demo-wrapper editor-w"
                                                        editorClassName="demo-editor input"
                                                        onEditorStateChange={onEditorStateChange}
                                                        keyBindingFn={myKeyBindingFn}
                                                        hashtag={{
                                                            separator: ' ',
                                                            trigger: '#',
                                                        }}
                                                        toolbarHidden={showEditor}
                                                        config={{ link: { trailingWhitespace: true } }}
                                                    />
                                                    {errors.description && <span className="error error-massege text-danger">{errors.description}</span>}
                                                </Form.Group>
                                                {selectBoard()}
                                                <Form.Group className='form-group col-sm-12'>
                                                    <Form.Check type="checkbox" label="Allow Comments" checked={videoFields.commenting} onChange={handleCommenting}/>
                                                </Form.Group>
                                                <Col sm={12} className='text-end'>
                                                    <Button variant="secondary"  className='me-2' disabled = {publishLoading || draftLoading || videoUploading} onClick={()=>handleCreateVideoPost(true)}>
                                                        { draftLoading ? <> <Spinner animation="border" size="sm"/> Please wait </> : 'Save to Draft'}
                                                    </Button>
                                                    <Button variant="primary" disabled = {publishLoading || draftLoading || videoUploading} onClick={()=>handleCreateVideoPost(false)}>
                                                        {publishLoading ?  <> <Spinner animation="border" size="sm"/> Please wait </> : 'Update'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>    
                                :    <Col sm={12} lg={10}>
                                        <div className='text-center spinner--loader'></div>
                                    </Col>
                            }
                        </Row>    
                    </Container>     
                </div>
                
                {isCropperOpen && 
                    <ImageCropper
                        isOpen={isCropperOpen}
                        src={imageForCrop.src}
                        onClose={() => handleCloseCropper()}
                        result={(val) => handeSaveCroppedImage(val)}
                    />
                }
                <Toaster/>
            </div>    
        </>
    );
}

export default EditVideo;