import React, { useState , useEffect} from 'react';
import { useSelector, useDispatch} from "react-redux";
import { Row, Col,Container,Tabs , Tab} from 'react-bootstrap';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import { getSearchData } from '../../redux/actions/search.action';
import 'bootstrap/dist/css/bootstrap.min.css'
import './searchStyle.css';
import '../../App.css';
import SearchPostsTab from './SearchPostsTab';
import { useLocation } from 'react-router-dom';
import SearchUserTab from './SearchUserTab';


function GlobalSearch(props) {
    const [tab, setTab] = useState('user');
    const [searchResult , setSearchResult] = useState('')
    const [loading , setLoading] = useState(true)
    const GlobalSearchData = useSelector(state =>state.search);
    const location = useLocation();
    const searchData = location.state;
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getSearchData(searchData))
    },[location])

    useEffect(()=>{
        if(GlobalSearchData && GlobalSearchData.searchResultList){
            if(GlobalSearchData.searchResultList.success){
                setSearchResult(GlobalSearchData.searchResultList.searchedData)
                setLoading(false)
            }
            if(GlobalSearchData && GlobalSearchData.searchError){
                setLoading(false)
            }

        }

    },[GlobalSearchData])

    const handleSelectTab = (tabName) => {
        setTab(tabName)
    }
    
    return(
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper audio--main--page search--result--page'>
                    <Container>
                        <Row className='justify-content-center'>
                        {!loading ?
                            <Col sm={12}>
                                <div className='Tabs--horizontal'>
                                <Tabs
                                    mountOnEnter={true} 
                                    unmountOnExit={true} 
                                    activeKey={tab} 
                                    id="profile--page--tabs" 
                                    onSelect={(e) => handleSelectTab(e)}
                                
                                >
                                    <Tab eventKey="user" title="Users">
                                        <SearchUserTab users = {(searchResult && searchResult.userResult)? searchResult.userResult : null}/>
                                    </Tab>
                                    <Tab eventKey="post" title="Posts">
                                        <SearchPostsTab post = {(searchResult && searchResult.postResult)? searchResult.postResult : null}/>
                                    </Tab>
                                </Tabs>
                                </div>
                            </Col> 
                        :
                            <Col sm={12}>
                                <div className='text-center spinner--loader'></div>
                            </Col>

                        }    
                        </Row>
                    </Container> 
                </div>
            </div>    
        </>
    )
}


export default GlobalSearch;