import React, { useState, useEffect } from "react";
import { Modal , Alert, Container, Row, Col, Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import BookingModel from './BookingModel';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// moment().format('MMMM Do YYYY, h:mm:ss a')


const BookCreater = () => {
    const [show , setShow] = useState(true);
    const alertValue = { message: '', show: false, variant: ''};
    const [alert , setAlert] = useState(alertValue);
    const [scheduleDetail , setScheduleDetail] = useState('')

    const scheduleState = useSelector(state => state.schedule);
    const navigate = useNavigate();

    const handleClose = () =>{
        setShow(false);
        navigate(-1)
    }


    const showAlertMessage = () => {
        return (
            <Container className="h-100">
                <Row className="justify-content-center">
                    <Col md={6}>
                        <Alert variant={alert.variant}> {alert['message']} </Alert>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-5">
                    <Col md={6}>
                        <Button variant="primary" onClick={()=>navigate(-1)}>Back</Button>
                        <ListGroup className="mt-3">
                            { (scheduleDetail.start_time)&& <ListGroupItem> Meeting Start Time<span>{moment(scheduleDetail.start_time).format('MMMM Do YYYY, h:mm a')} </span></ListGroupItem>}
                        </ListGroup>
                        <p>We have shared meeting ID on your registered email address.</p>
                    </Col>
                </Row>
            </Container>
        )
    }



    useEffect(()=>{
        if(!scheduleState.error){
            if(scheduleState.scheduleBooked && scheduleState.scheduleBooked.success){
                setShow(false);
                alert['show'] = true
                alert['message'] = scheduleState.scheduleBooked.message
                alert['variant'] = "success"
                setScheduleDetail(scheduleState.scheduleBooked.meetingDetail)
            }
        }

    },[scheduleState])


    return (
        <>
            <Modal 
                show={show} 
                onHide={handleClose} 
                centered 
                size='md'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Book Appointment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BookingModel/>
                </Modal.Body>
            </Modal>

            <div className="booking--details">
                {alert['show'] && showAlertMessage()}
            </div>
        </>
    )
}

export default BookCreater;