import React, { useEffect, useState } from "react";
import {useNavigate , useParams} from "react-router-dom";
import { Button, Row, Col, Form, Container , Spinner} from 'react-bootstrap';
import { FaCogs, FaCreativeCommonsPdAlt } from 'react-icons/fa';
import { useSelector, useDispatch } from "react-redux";
import { EditorState, convertToRaw, getDefaultKeyBinding ,ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import PageHeader from "../Header/HeaderTop";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {IoClose} from 'react-icons/io5';
import { toast, Toaster } from "react-hot-toast";
import {notValid, emailValidation } from "../../utils/validations";
import { editTextPost} from "../../redux/actions/textPost.action";
import { getSinglePost ,getBoardPostList } from "../../redux/actions/post.action";
import { checkHTML , convertToPlainText , valid} from '../../utils/validations';
import ImageCropper from "../Create/CreateTabs/imageCropper";
import '../Shop/shopStyle.css';
import '../../App.css';
import SidebarMenu from "../Sidebar/SidebarNav";




function EditText() {
    const{slug} = useParams();  
    const intialValue = {postId : '' , name:'' ,   description: '', thumbnail: '',  isStream: true,  commenting: true , board_name: '',board_id: '',};
    const [textFields , setTextFields] = useState(intialValue) ;
    const [boardList , setBoardList] = useState('')
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [errors , setErrors] = useState('');
    const [loading , setLoading] = useState(true);
    const [draftLoading , setDraftLoading] = useState(false);
    const [publishLoading , setPublishLoading] = useState(false);
    const [ isCropperOpen, setCropperOpen ] = useState(false);
    const [ imageForCrop, setImageForCrop ] = useState(null);
    const [showEditor , setShowEditor] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const apiResult = useSelector(state => state.textPost);
    const getPostResult = useSelector(state => state.post);
   
    useEffect(()=>{
        setLoading(true)
        dispatch(getSinglePost(slug));
        dispatch(getBoardPostList())
    },[]);

    useEffect(()=>{
        if(getPostResult && getPostResult.singlePostSuccess){
            let copyTextFields = {...textFields};
            copyTextFields.postId =  getPostResult.singlePostSuccess.Post.id ;
            copyTextFields.name =  getPostResult.singlePostSuccess.Post.name || '';
            copyTextFields.thumbnail = getPostResult.singlePostSuccess.Post.thumbnail || '';
            copyTextFields.commenting = getPostResult.singlePostSuccess.Post.commenting ;
            copyTextFields.isStream = getPostResult.singlePostSuccess.Post.isStream || true ; 
            copyTextFields.board_name = getPostResult.singlePostSuccess.Post.board_name || '';
            copyTextFields.board_id  = getPostResult.singlePostSuccess.Post.board_id || ''
            if(getPostResult.singlePostSuccess.Post.description){
                const blocksFromHtml = htmlToDraft(getPostResult.singlePostSuccess.Post.description);
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState)
                copyTextFields.description = getPostResult.singlePostSuccess.Post.description
            }
            setTextFields(copyTextFields)
        }   
        if(getPostResult && getPostResult.boardPostList){
            setBoardList(getPostResult.boardPostList.boardPosts)
        }
        // if(getPostResult && getPostResult.boardPostError){
        //     toast.error(getPostResult.boardPostError.message, {
        //         position: 'top-center', 
        //     });
        // }
        if(getPostResult.singlePostError){
            toast.error(apiResult.textPostError, {
                position: 'top-center', 
            });
        }
        setLoading(false)
    },[getPostResult])

    const handleChange = (e) =>{
        const {value , name } = e.target ; 
        setTextFields({...textFields, [name]: value })
        setErrors({ ...errors, [name]: '' })
        
    }

    const handleCommenting = (e) =>{ 
        const copyTextFields = {...textFields} ;
        copyTextFields.commenting = e.target.checked
        setTextFields(copyTextFields)
    }

/*************************************   crop Image Function  ****************************************************************************/

    const handleCloseCropper = () => {
        setImageForCrop(null)
        setCropperOpen(false)
    }

    const handeSaveCroppedImage = (dataURI) => {
        const imageFile = dataURItoBlob(dataURI);
        let copyTextFields = { ...textFields }
        copyTextFields['thumbnail'] = imageFile
        setTextFields(copyTextFields)
    }

    const dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], {type:mimeString});
    }


    const handleOpenCropper = () => {
        setCropperOpen(true)
        let data = {
            // src: URL.createObjectURL(textFields.thumbnail)
            src : textFields.thumbnail
        }
        setImageForCrop(data)
    }

/*********************************************************************************************************************************** */ 


/*******************************************     upload file / remove file handle function  ***********************************************/

    const handleThumnail = (e)=>{
        const allImages = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'];
        let errorMessages = {...errors};
        const { files } = e.target ; 
        const copyTextFields = {...textFields}
        if(files && files.length < 2 ){
            const file = files[0];
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.split('.').pop();
            if(allImages.includes(fileExtension)){
                copyTextFields.thumbnail = file
                setTextFields(copyTextFields)
            }else{
                errorMessages['thumnail'] = ' Please use only  png, jpg, jpeg, JPG, JPEG, PNG'
                setErrors(errorMessages)
            }    
        }

    }


    const handleRemoveFile = () =>{
        const copyTextFields = {...textFields}
        copyTextFields.thumbnail = '' ;
       setTextFields(copyTextFields)
    }

/************************************************************************************************************************************** */

/***************    using for form fields  validation  *************************************/
    const validate = () =>{
        let isValid = true ;
        let errorMessages = {};
        let desc = checkHTML(textFields.description) ? convertToPlainText(textFields.description) : textFields.description
        if (notValid(desc)) {
            errorMessages['description'] = 'Description is required'
            isValid = false;
        }
        if (notValid(textFields.name)) {
            errorMessages['name'] = 'Title is required'
            isValid = false;
        }

        if (!isValid) {
            setErrors({ ...errors, ...errorMessages });
        }
        return isValid;
    }

    const checkHTML = param => {
        return new RegExp(/(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/gm).test(param)
    }


    const convertToPlainText = (html) => {
        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(html, 'text/html');
        return parsedDocument.body.textContent || '';
    };
/************************************************************************************************ */

/**********************    handle description  /////   text editor  function using draft.js for text editing        ******/

    const onEditorStateChange = (editorState) => {
        const copyTextFields = {...textFields} ;
        let errorMessages = {...errors};
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const description = draftToHtml(rawContentState);
        copyTextFields.description = description
        errorMessages.description = '' ;
        setTextFields(copyTextFields)
        setErrors(errorMessages)
        setEditorState(editorState);
    };


    const myKeyBindingFn = event => {
        return getDefaultKeyBinding(event);
    }
/**************************************************************************************************************************** */


/************************  form submit function  //  publish or save post in draft depends status  *******************/
    const handleUpdateTextPost = (draft) =>{
        const copyTextFields = {...textFields}
        if(validate()){
            if(draft){
                copyTextFields.status = '2'
                setPublishLoading(false)
                setDraftLoading(true)
            }else{
                setPublishLoading(true)
                setDraftLoading(false)
            }
            const formData = new FormData();
            for (const [key, value] of Object.entries(copyTextFields)) {
                formData.append(key, value)
            }  
            dispatch(editTextPost(formData)) ;
        }
    }
/**************************************************************************************************** */

    useEffect(()=>{
        if(apiResult && apiResult.editTextPost){
            setLoading(false)
            toast.success(apiResult.editTextPost.message, {
                position: 'top-center', 
            });
            setTextFields(intialValue)
            navigate('/text')
        }
        if(apiResult && apiResult.textPostError){
            setLoading(false)
            toast.error(apiResult.textPostError, {
                position: 'top-center', 
            });
        }
    },[apiResult])
    const selectBoard = () => {
        if(boardList && boardList.length > 0){
            return (
                <Form.Group className='form-group col-sm-12'>
                    <Form.Label>Realm</Form.Label>
                    <Form.Control value={textFields.board_id || '' } onChange= {handleBoard} as="select">
                        <option selected>Select</option>
                        {boardList.map((item , idx)=>{
                            return (
                                <option key={idx} value={item.id}>{item.name}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
            )
        }
    }

    const handleBoard = (e)=>{
        let copyTextFields = {...textFields};
        copyTextFields.board_id = e.target.value
        setTextFields(copyTextFields)

    }

    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper create--shop--form'>
                    <Container>
                        <Row className='justify-content-center pt-3'>
                            {!loading ?
                                <Col sm={12} lg={10}>
                                    <Form >
                                        <Row>
                                            <Form.Group className='form-group col-sm-12'>
                                                <span className='editor--tool' onClick = {()=>setShowEditor(!showEditor)}><FaCogs /></span>
                                            </Form.Group>
                                            <Form.Group className='form-group col-sm-12'>
                                                <Editor
                                                    editorState={editorState}
                                                    wrapperClassName="demo-wrapper editor-w"
                                                    editorClassName="demo-editor input"
                                                    onEditorStateChange={onEditorStateChange}
                                                    keyBindingFn={myKeyBindingFn}
                                                    hashtag={{
                                                        separator: ' ',
                                                        trigger: '#',
                                                    }}
                                                    toolbarHidden={showEditor}
                                                    config={{ link: { trailingWhitespace: true } }}
                                                />
                                                {errors.description && <small className="error error-massege text-danger">{errors.description}</small>}
                                            </Form.Group>
                                            <Form.Group className='form-group col-sm-12'>
                                                <Form.Label>Title</Form.Label>
                                                <Form.Control type="text" name= 'name'  value= {textFields.name} onChange={handleChange} required />
                                                {errors.name && <small className="error error-massege text-danger">{errors.name}</small>}
                                            </Form.Group>
                                            {textFields.thumbnail ?
                                                <>
                                                    <Form.Group className='form-group col-sm-12'>
                                                        <div className="post--thumb--upload">
                                                            <img src={typeof textFields.thumbnail === 'object' ? URL.createObjectURL(textFields.thumbnail) : textFields.thumbnail}/>
                                                            {/* <span className="post--media--edit" onClick={() => handleOpenCropper()}>Edit</span> */}
                                                            <span className="post--media--close" onClick={handleRemoveFile}><IoClose/></span>
                                                        </div>
                                                    </Form.Group>
                                                </>
                                                :
                                                <Form.Group className='form-group col-sm-12'>
                                                    <Form.Label>Add Thumbnail</Form.Label>
                                                    <input type='file' hidden id="upload--header" onChange ={handleThumnail} />
                                                    <label className='label--upload' htmlFor="upload--header">Choose File</label>
                                                    {errors.thumbnail && <small className="error error-massege text-danger">{errors.thumbnail}</small>}
                                                </Form.Group>
                                            }
                                            {selectBoard()}
                                            <Form.Group className='form-group col-sm-12'>
                                                <Form.Check type="checkbox" label="Allow Comments" checked={textFields.commenting} onChange= {handleCommenting}/>
                                            </Form.Group>
                                            <Col sm={12} className='text-end'>
                                                <Button variant="secondary"  className='me-2' disabled = {publishLoading || draftLoading} onClick={()=>handleUpdateTextPost(true)}>
                                                    { draftLoading ? <> <Spinner animation="border" size="sm"/> Please wait </> : 'Save to Draft'}
                                                </Button>
                                                <Button variant="primary"  disabled = {publishLoading || draftLoading} onClick={()=>handleUpdateTextPost(false)}>
                                                    { publishLoading ? <> <Spinner animation="border" size="sm"/> Please wait </>: 'Update'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    {isCropperOpen && 
                                        <ImageCropper
                                            isOpen={isCropperOpen}
                                            src={imageForCrop.src}
                                            onClose={() => handleCloseCropper()}
                                            result={(val) => handeSaveCroppedImage(val)}
                                        />
                                    }
                            
                                </Col>
                            :
                                <Col>
                                    <div className='text-center spinner--loader'></div>
                                </Col>          
                            }      
                        </Row>
                    </Container>
                </div>
                <Toaster/>
            </div>
        </>
    );
}

export default EditText;