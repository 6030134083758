import React, { Component } from 'react';
import { Button, Form, OverlayTrigger, Popover, Tooltip, Spinner } from 'react-bootstrap';
import { BsSave, BsTrash, BsPencilSquare, BsEmojiSmile, BsFileEarmark, BsFillXCircleFill, BsFileEarmarkPdf, BsFileEarmarkZip, BsFileEarmarkMusic, BsFiletypeTxt } from 'react-icons/bs';
import { FaPaperPlane, FaTimes } from 'react-icons/fa';
import { Link} from "react-router-dom";
import { ImAttachment } from 'react-icons/im';
import moment from 'moment';
import toast from 'react-hot-toast';
import ReactFancyBox from 'react-fancybox';
import Picker from "emoji-picker-react";
import { connect } from 'react-redux';
import API from '../../helpers/api';
import { currentUser, getToken } from '../../helpers/auth';
import { sendMessage, removeMessage, updateMessage, updateUnseenCount, loadMoreMessege , updateUnseenCountOne} from '../../redux/actions/message.action';
import { decodedLoggedUser } from '../../helpers/auth';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-fancybox/lib/fancybox.css'
import './messengerStyle.css';
import '../../App.css';
import { getChatUsers } from '../../redux/actions/message.action';

// const BaseURL = 'https://thegalaxy.me:4443'
const icons = {
    pdf: <BsFileEarmarkPdf />,
    zip: <BsFileEarmarkZip/>,
    text: <BsFiletypeTxt/>,
    audio: <BsFileEarmarkMusic/>
}
const txt_icon = [
    "txt",
    "rtf",
    "doc",
    "docx",
    "xml",
    "xsd",
    "ini",
    "config",
    "log",
    "err",
    "json",
    "yml",
    "yaml"
];
const music_ext = ["mp3", "m4a", "mpeg", "aac", "amr", "ogg", "wbm", "voc", "raw", "wav"];
const token = getToken();
class Chat extends Component {
    constructor(props){
        super(props)

        this.state = {
            loggedUserId:'',
            user: '',
            senderid: '',
            chatid: '',
            receiverid: '',
            initialMesgs:[],
            messageBkup:[],
            messages: [],
            editMessage: '',
            edit: '',
            editLoader: false,
            editMessageId: null,
            text_message: '',
            loaded: false,
            moreData: true,
            loadMsg: false,
            deleteLoader: false,
            deleteMessageId: null,
            selected_user: null,
            files: [],
            msg_err: false,
            scrollBottomOnLoad: 1,
            current_chat_index: null,
            new_message_id: null,
            initial_loader: 0,
            fileUploading: false,
            fileLoader: false , 
        }

        this.file_url = '';
        this.fancyBoxRef = React.createRef();
       
    }

    UNSAFE_componentWillMount() {
        this.scrollToMyRef();
        if(this.props.userAuth && this.props.userAuth.loggedIn){
            const token = decodedLoggedUser(this.props.userAuth.loggedIn)
            this.setState({ loggedUserId: Number(token.aud) });
        }
    }

    componentDidMount =() => {
        document.addEventListener('mousedown', this.handleCloseOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleCloseOutside);
    }


    handleCloseOutside = (event) => {
        if (this.fancyBoxRef.current && this.fancyBoxRef.current.box !== null){
            this.fancyBoxRef.current.close()
        }
    };

    scrollToMyRef = () => {
        const container =  document.getElementById('messengerBox');
        if(container){
            container.scrollTop = container.scrollHeight - container.clientHeight;
        }
    };

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    extractFileName = url => {
        var arr = url.split('/');
        var strFile;
        if (arr && arr.length && arr[4]) {
            strFile = arr[4];
            strFile = strFile.split('?');
            strFile = strFile[0];
        }
        return strFile;
    }

    check_is_image = name => {
        return (name.match(/\.(jpeg|jpg|gif|png)$/) != null);
    }

    check_is_video = name => {
        return (name.match(/\.(mp4|mkv|avi|flv|wmv|quicktime|webm)$/) != null);
    }

    check_is_audio = name =>{
        return (name.match(/\.(mp3|mpeg|wav|ogg|m4a|wma|ape|opus)$/) != null);
    }

    removeFile = async () => {
        if (this.file_url) {
            var arr = this.file_url.split('/');
            if (arr && arr.length && arr[4]) {
                var strFile = arr[4];
                strFile = strFile.split('?');
                strFile = strFile[0];
            }
            API.apiPostUrl('messenger', '/remove-file', { fileUrl:this.file_url  });
        }
        this.file_url = ""
        this.setState({ files: [] })
    }

    commonScroll = (chatId) =>{
        const container =  document.getElementById('messengerBox');
        if(container){
            document.querySelector('#message_'+chatId).scrollIntoView({block: "end"});
        }
    }
    UNSAFE_componentWillReceiveProps = async nextProps => {
        if (this.props !== nextProps) {
            const { loggedUserId } = this.state
            var { users, current_chat_index, chat_messages, messageUpdated, messageDeleted, messageAdded , invited_users} = nextProps;
            if (users && users.length) {
                setTimeout(() => {
                    if( this.state.selected_user === null || ( this.state.selected_user !== current_chat_index)){
                        this.scrollToMyRef();
                        this.setState({
                            selected_user: current_chat_index,
                        })
                    }
                }, 350)
                var user_data = users[current_chat_index];
                if(user_data){
                    if(this.props.users.length !== users.length){
                        this.setState({messages:[]},()=>this.loadLatestMessage(user_data.id))
                    }
                    this.setState({
                        user: user_data.senderid === loggedUserId ? user_data.receiver : user_data.sender,
                        senderid:loggedUserId,
                        chatid: user_data.id,
                        receiverid: user_data.senderid !== loggedUserId ? user_data.senderid : user_data.receiverid,
                    })
                    if(this.state.messages.length === 0){
                        this.loadLatestMessage(user_data.id);
                    }
                    if( this.state.current_chat_index !== current_chat_index ){
                        this.setState({
                            current_chat_index,
                            new_message_id: null
                        }, () => {
                            this.loadLatestMessage(user_data.id);
                        })
                    }
                }
                if(messageDeleted || messageUpdated){
                    let messages = this.state.messages.map(p =>
                        p.id === chat_messages[0].id
                            ? chat_messages[0]
                        : p
                    );

                    let messageBkup = this.state.messageBkup.map(p =>
                        p.id === chat_messages[0].id
                            ? chat_messages[0]
                        : p
                    );

                    this.setState({ 
                        messages : messages,
                        messageBkup: messageBkup,
                        new_message_id: null
                    })
                }
                var count = this.state.messages.some(msg => (msg.unseen && Number(msg.receiverid) === Number(loggedUserId)));
                if((this.state.messages.length > 0) && (this.state.messages[0].chatid == chat_messages[0].chatid) && (this.state.new_message_id !== chat_messages[0].id) && (messageAdded)){
                    let filterMesg =  this.state.messages.filter((msg) => msg.id !== chat_messages[0].id)
                    let msgbckup = [ ...filterMesg, chat_messages[0] ]
                    this.setState({ 
                        messages : [ ...filterMesg, chat_messages[0] ],
                        messageBkup: msgbckup.reverse(),
                        new_message_id : chat_messages[0].id,
                    },() => {
                        this.commonScroll(chat_messages[0].id);
                    })
                    var count = this.state.messages.some(msg => (msg.unseen && Number(msg.receiverid) === Number(loggedUserId)));
                    if (count) {
                        if(user_data){
                            this.props.updateUnseenCount(user_data.id)
                        }
                    }
                }
                
            }
            
        }

    }

     // call api function here 
    loadLatestMessage = async (chatId) => {
         const res = await API.apiGet(`messenger`, `/latest-user-message?chatid=${chatId}`)
        if(res.data && res.data.success && res.data.data){
            let chat_msg = [...res.data.data];
            let msg = [...res.data.data.reverse()];
            this.setState({
                messages:msg,
                messageBkup:chat_msg,
            },()=>{
                this.scrollToMyRef()
            })
        }
    }

    sendMessage = async (e) => {
        var { senderid, receiverid, chatid, text_message } = this.state;
        if (text_message.length) {
            var obj = {
                senderid,
                receiverid,
                chatid,
                message: text_message
            }
            await this.props.sendMessage(obj);
            this.setState({ text_message: '' })
        }
    }

    sendFileMessage = async (e) => {
        var { senderid, receiverid, chatid } = this.state;
        if (this.file_url.length) {
            var obj = {
                senderid,
                receiverid,
                chatid,
                message: this.file_url
            }
            await this.props.sendMessage(obj);
            this.file_url = ''
            this.setState({ files: [] })

        }
    }

    handleMesssage = e => {
        if (e.keyCode === 13) this.sendMessage();
        if (this.file_url && e.keyCode === 13) this.sendFileMessage();
    }

    removeMessage = async id => {
        var { senderid, receiverid, chatid } = this.state;
        var obj = {
            senderid,
            receiverid,
            chatid,
            message_id: id
        }
        const updatedItems = this.state.messages.filter(item => item.id !== id);
        this.setState({messages:updatedItems})
        await this.props.removeMessage(obj);
        this.setState({ deleteLoader: true, deleteMessageId: id });
    }

    removeFileMessege = async (id, file_url) => {
        var { senderid, receiverid, chatid } = this.state;
        var obj = {
            senderid,
            receiverid,
            chatid,
            message_id: id
        }
        const updatedItems = this.state.messages.filter(item => item.id !== id);
        this.setState({messages:updatedItems})
        await this.props.removeMessage(obj);
       API.apiPostUrl('messenger', '/remove-file', { fileUrl:file_url  });
    }

    async editMessage(id) {
        var { senderid, receiverid, chatid, editMessage } = this.state;
        var obj = {
            senderid,
            receiverid,
            chatid,
            id,
            message: editMessage
        }
        await this.props.updateMessage(obj);
        this.setState({ editMessage: '', edit: '', editLoader: true, editMessageId: id });
    }

    onEmojiClick = (emojiObject, event ) => {
        this.setState({ text_message: this.state.text_message + "" + emojiObject.emoji });
    };

    mediaUplaod = async e => {
        this.handleUploadFileToServer(e.target.files[0]);
        this.setState({fileLoader:true})
        this.setState({
            files: this.state.files.concat(Array.from(e.target.files))
        })
    }

    handleUploadFileToServer = async (file) => {
        try {
            const formData = new FormData();
            formData.append('thumbnail', file);
            const response = await API.apiUploadFileURL('messenger', `/get-file-url`, formData);
            if(response.data && response.data.success){
                this.file_url = response.data.fileUrl
            }
        } catch (error) {
            toast.error('Oops, unable to upload file');
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.sendMessage()
        if(this.file_url) this.sendFileMessage(e);
    }

    handleDisplayMessage = (chat, is_url, is_image, is_video,icon, fileName,is_audio) => {
        if( is_url && chat.messege.includes("video.thegalaxy.me") ){
            if(is_image){
               return <ReactFancyBox  ref={this.fancyBoxRef} thumbnail={chat.messege} image={chat.messege}/>
            }else if(is_video){
                return  <video controls src={chat.messege} />
            }else if(is_audio){
                return  <audio controls src={chat.messege} />
            }else{
                return <span className='download--file'>
                            <a href={`${chat.messege}`} download={fileName}>
                                <span className="file--type--icon">
                                    {icon}
                                </span>
                                <span>
                                    <h6 className='mb-1'>{fileName}</h6>
                                    <div className='download--file--shared'>
                                        <p className="text-left"><small>click here to download</small></p>
                                    </div>
                                </span>
                            </a>
                        </span>
            }

        } else {
            return chat.messege
        }
        
    }

    onHandleScroll = async e => {
        let element = e.target;    
        if (element.scrollTop === 0 && !this.state.loadMsg) {
            let lastPost = this.state.messages[0]
            if(lastPost && lastPost !== undefined){
                this.setState({ loadMsg: true })
                await this.props.loadMoreMessege(lastPost.id, lastPost.chatid)
                if(this.props.more_messeges && this.props.more_messeges.length){
                    let mesg = this.state.messageBkup;
                    let loadmore_message_id = this.props.more_messeges[0].id;
                    mesg.push(...this.props.more_messeges)
                    this.setState({ 
                        messageBkup: mesg,
                    })
                    let mesg2 = [...mesg]
                    setTimeout(() => {
                        this.setState({
                            messages: mesg2.reverse(),
                            loadMsg: false,
                        },()=>{
                            this.commonScroll(loadmore_message_id);
                        })
                    }, 250)
                } else {
                    this.setState({ loadMsg: false })
                } 
            }
        }
    }



    showMediaItems = (file) =>{
        if(file){
            const FileType = file.type.split('/')
            if(FileType[0] === 'image'){
                return (
                    <img src={typeof file === 'object' ? URL.createObjectURL(file) : null}/>
                )
            }
            else if( FileType[0] === 'video'){
                return(
                    <video src={typeof file === 'object' ? URL.createObjectURL(file) : null} controls/>
                )
            }
            else if(FileType[0] === 'audio'){
                return (
                    <audio src={typeof file === 'object' ? URL.createObjectURL(file) : null} controls/>
                )
            }
            else if(FileType[1] === "zip"){
                return (
                    <>
                        <span className="file--type--icon">
                        {icons.zip}
                        </span>
                        <span>
                            <h6 className='mb-1'>{file.name}</h6>
                        </span>
                    </>

                )
            }
            else if(FileType[1] === "pdf"){
                return (
                    <>
                        <span className="file--type--icon">
                        {icons.pdf}
                        </span>
                        <span>
                            <h6 className='mb-1'>{file.name}</h6>
                        </span>
                    </>

                )
            }
            else {
                return (
                    <>
                        <span className="file--type--icon">
                            {icons.text}
                        </span>
                        <span>
                            <h6 className='mb-1'>{file.name}</h6>
                        </span>
                    </>

                )

            }
        }
    }

    render(){
        const { user, messages, loadMsg, text_message , files } = this.state;
        return (
            <div className='Gx--chatt--box'>
                {user
                ?<> 
                    <div className="user--data">
                        
                            <span className="user--icon">
                            <Link to={`/${user.username}`}>
                                <img src={user.image ? user.image : '/images/user.png'} alt="user" />
                            </Link>
                            </span>
                        
                        <div className="user--name">
                            <Link to={`/${user.username}`}>
                                <h5>{user.first} {user.last}</h5>
                            </Link>
                            <p>
                                <span className={`status--circle ${user.is_online ? 'online--green' : 'offline--red'}`} />
                                {user.is_online ? 'Online' : 'Offline'}
                            </p>
                        </div>
                    </div>
                    <div className='chat--user--area' id="messengerBox" onScroll={(e) =>this. onHandleScroll(e)}>
                        <div className='user--chat--body'>
                            {loadMsg && <div className='text-center spinner--loader msg-loader' />}
                            {(messages && messages.length) 
                            ?   messages.map((chat, i) => {
                                if(chat.deleted == 0){
                                    var is_url = chat.messege;
                                    if (is_url && chat.messege.includes("video.thegalaxy.me")) {
                                        var icon = "fa fa-file";
                                        var file_name = this.extractFileName(chat.messege);
                                        var is_image = this.check_is_image(file_name);
                                        var is_video = this.check_is_video(file_name);
                                        var is_audio = this.check_is_audio(file_name)
                                        if (file_name && file_name.split(".")[1] === "zip") {
                                            icon = icons.zip
                                        }
                                        if (file_name && file_name.split(".")[1] === "pdf") {
                                            icon = icons.pdf
                                        }
                                        if (file_name && txt_icon.includes(file_name.split(".")[1])) {
                                            icon = icons.text
                                        }
                                        if (music_ext.includes(file_name.split(".")[1])) {
                                            icon = icons.audio
                                        }
                                    }
                                    // other--stack, reply--stack
                                    return (
                                    
                                            <p className={`user--stack ${chat.senderid !== user.id ? 'reply--stack' : 'other--stack'  }`} id={"message_"+ chat.id}>
                                                {(chat.senderid === user.id) && <img src={chat.chat_sender.image ? chat.chat_sender.image : '/images/user.png'} alt="Other" />}
                                                <div className='user--message'>
                                                    {chat.deleted == 1 
                                                    ? 'Message Removed' 
                                                    : ((chat.id === this.state.edit) && (chat.senderid !== user.id))  
                                                        ?   <>
                                                                <Form.Group className='edit--message'>
                                                                    <Form.Control className="input" onChange={e => this.setState({ editMessage: e.target.value })} value={this.state.editMessage} type="text" placeholder="Write a message..." />
                                                                </Form.Group>
                                                                <div className="edit--message--action">
                                                                    <span onClick={() => this.editMessage(chat.id)} aria-hidden="true"> <BsSave /></span>
                                                                    <span onClick={() => this.setState({ edit: '', editMessageId: null, editLoader: false })} aria-hidden="true"><FaTimes /></span>
                                                                </div>
                                                            </>
                                                        :   this.handleDisplayMessage(chat, is_url, is_image, is_video, icon, file_name , is_audio)
                                                    }
                                                    <small>{moment(chat.createdAt).fromNow()}</small>
                                                    <div className='chat--action--buttons'>
                                                        { ((chat.senderid !== user.id) && !(chat.id === this.state.edit) && chat.deleted != 1)
                                                        ?   <>
                                                                {!is_url && <span onClick={() => this.setState({ editMessage: chat.messege, edit: chat.id })}><BsPencilSquare/></span>}
                                                                <span onClick={() => is_url ? this.removeFileMessege(chat.id, chat.messege) : this.removeMessage(chat.id)}><BsTrash /></span>  
                                                            </> 
                                                        : null}
                                                    </div>
                                                </div>
                                                { (chat.senderid !== user.id) && <img src={chat.chat_sender.image ? chat.chat_sender.image : '/images/user.png'} alt="Logged User" /> }
                                            </p>
                                    
                                    )
                                    }
                                })
                            :   null
                            }
                        </div>
                    </div>
                  





                  
                    <div className='chat--input--box'>
                        
                        <Form onSubmit={(e) => this.handleSubmit(e)}> 
                            <Form.Control as="textarea"
                                type="text" 
                                placeholder="Write a message here.." 
                                value={this.state.text_message} 
                                onChange={(e) =>  this.setState({ text_message: e.target.value, msg_err: false })} 
                                onKeyDown={this.handleMesssage}
                            />

                        {this.state.files.length > 0 &&
                            <div className="file-uplaode-box">
                            { this.state.files.map((file, fileIdx) => (
                                    <div className='file-upload-single' key={fileIdx}>
                                        <span className='file-upload-icon '>
                                        {this.showMediaItems(file)}
                                            {/* <BsFileEarmark color='#fff' width={12} height={30}/> */}
                                        </span>
                                        {/* <span className='file-upload-name'>
                                        {file.name}
                                        </span> */}
                                        <BsFillXCircleFill onClick={() => this.removeFile()}/>
                                    </div>
                                ))}
                        </div>}


                             <div className='iconWrappers'> 
                             <div className='iconWrappers_left'>
                                <label className='chat--attach--file' htmlFor='attach--file'>
                                    <input 
                                        type='file' 
                                        name='file' 
                                        id='attach--file' 
                                        hidden 
                                        disabled={this.state.files.length} 
                                        onChange={this.mediaUplaod} 
                                    />
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id="attachment">Attach file</Tooltip>}
                                    >
                                        <ImAttachment />
                                    </OverlayTrigger>
                                </label>

                            <OverlayTrigger
                                rootClose
                                trigger="click"
                                key="popover-positioned-emoji"
                                placement="top"
                                overlay={
                                    <Popover id="popover-positioned-emoji">
                                        <Picker
                                            onEmojiClick={this.onEmojiClick}
                                            disableAutoFocus={true}
                                        />
                                    </Popover>
                                }
                            >
                                <Button variant='secondary' ><BsEmojiSmile /></Button>
                            </OverlayTrigger>
                            </div>
                            <Button 
                                variant='primary' 
                                onClick={(e) => this.handleSubmit(e)} 
                                type="submit"
                            >
                                 <FaPaperPlane />
                            </Button>
                        </div>
                        </Form>
                    </div>
                </>
                : <div><h5 className='text-center p-5'>Connect with people to chat</h5></div>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { messenger ,auth} = state;
    return {
        users: messenger.chat_users.success ? messenger.chat_users.data : [],
        current_chat_index: messenger.current_chat,
        chat_messages: messenger.chat_messages,
        more_messeges: messenger.more_messeges,
        messageUpdated: messenger.updated,
        messageDeleted: messenger.removed,
        messageAdded: messenger.added,
        userAuth : auth,
        invited_users: messenger.invited_users
    }
}

const dispatchActions = {
    sendMessage,
    removeMessage,
    updateMessage,
    updateUnseenCount,
    loadMoreMessege,
    
}

export default connect(mapStateToProps, dispatchActions)(Chat)