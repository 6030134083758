import React, { Component } from 'react';
import { Form, ListGroup, Button, Modal } from 'react-bootstrap';
import { BsPlus } from 'react-icons/bs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import InvitationChatModel from './InvitationChatModel';
import { getChatUsers } from '../../redux/actions/message.action';
import { currentUser } from '../../helpers/auth';
import moment from 'moment';
import { SET_CURRENT_CHAT_USER } from '../../redux/actions/types';
import { decodedLoggedUser } from '../../helpers/auth';
import { updateUnseenCount } from '../../redux/actions/message.action';
class ChatUsers extends Component{
    constructor(props){
        super(props);

        this.state = {
            show_modal: false,
            users:[],
            loading: true,
            loggedUserId:'',
            FilterUserList:'',
            hide_count : false ,
            search : '' , 
            currentUser : 0
        }
        this.timeout = null;
    }

    async componentDidMount() {
        await this.fetchChatUsers();
        if(this.props.userAuth && this.props.userAuth.loggedIn){
            const token = decodedLoggedUser(this.props.userAuth.loggedIn)
            this.setState({ loggedUserId: Number(token.aud) });
        }
    }

    fetchChatUsers = async (data) => {
        await this.props.getChatUsers(data)
        this.setState({ loading: true })
        if(this.props.chat_users && this.props.chat_users.success){
            this.setState({ 
                users: this.props.chat_users.data, 
                const_users: this.props.chat_users.data 
            })
            if(this.props.chat_users.data ){
                await this.props.setCurrentChatUser(0);
            }
            this.setState({ loading: false })
        }else{
            this.setState({ loading: false })
        }
        
    }

    UNSAFE_componentWillReceiveProps = async (nextProps) => {
        if(nextProps.chat_users && nextProps.chat_users.success){
            this.setState({ 
                users: nextProps.chat_users.data, 
                const_users: nextProps.chat_users.data 
            })
            this.setState({ loading: false })
        }
    }

    onChange = async e => {
        this.setState({search:e.target.value})
    }

    validURL(str) {
        //eslint-disable-next-line
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    extractFileName = url => {
        var arr = url.split('/');
        var strFile;
        if (arr && arr.length && arr[4]) {
            strFile = arr[4];
            strFile = strFile.split('?');
            strFile = strFile[0];
        }
        return strFile;
    }

    onMediaClick = async (i , user) => {    
        await this.props.setCurrentChatUser(i);
        var users = this.state.users
        var user = this.state.users[i];
        if(user){
            this.setState({currentUser:i})
            if(user.chat_messages  && user.chat_messages.length ){
                user.chat_messages.forEach(message => {
                    if (Number(message.chat_receiver.id) === Number(this.state.loggedUserId)) {
                    message.unseen = false;
                    }
                });
                users[i] = user
                this.setState({users});
            }
            await this.props.updateUnseenCount(user.id);

             
        }

        const obj = {}

       
    }

    /**
     * offline--idle
     * online--green
     * offline--red
     */

    debounceSearchUsers = (data) => {
        if (this.timeout) {
            clearTimeout(this.timeout); // Clear the previous timeout
        }
        this.timeout = setTimeout(() => {
            this.fetchChatUsers(data); // Call searchUsers after the delay
        }, 1200); // Set the delay to 300 milliseconds
    };

    componentDidUpdate(prevProps, prevState){
        if(prevState.search !== this.state.search){
            let value = this.state.search.trim().toLowerCase();
            const data = { searchUser: value };
            // Assuming that searchUser is a prop function
            this.setState({
                loading : true ,
                users: [] ,
            },()=>this.debounceSearchUsers(data))
        }
    }
    
    render(){
        const { users, loading, loggedUserId } = this.state ; 
        const check = ['undefined' , undefined , null , '' , 'null' , 0 , []]
        return(
            <div className='Gx--chat--user'>
                <Form>
                    <Form.Control type="text" placeholder="Search here.." onChange={(e) => this.onChange(e)}/>
                    <Button variant='secondary' onClick={() => this.setState({ show_modal: true })}><BsPlus /></Button>
                </Form>

                <ListGroup className='chat--user--list'>

                   {loading &&  <div className='text-center spinner--loader'></div>}
                   {(!loading && users.length > 0)
                    &&  users.filter(user => user.receiver?.first?.toLowerCase().includes(this.state.FilterUserList?.toLowerCase())).length > 0
                        ? this.state.users.filter(user => user.receiver?.first?.toLowerCase().includes(this.state.FilterUserList?.toLowerCase())).map((chat, i) => {
                                var show = chat.sender;
                                if (chat.senderid === loggedUserId) {
                                    show = chat.receiver;
                                }
                                else {
                                    show = chat.sender;
                                }
                                if (!show) return;
                                let { messege, unseen, receiverid, deleted } = chat.chat_messages.length && chat.chat_messages[0];
                                
                                let unseen_count = chat.chat_messages.filter((message) => message.unseen === true && message.chat_receiver.id === loggedUserId).length;
                                var is_url = this.validURL(messege);
                                if (is_url && messege.includes("video.thegalaxy.me")) {
                                    var file_name = this.extractFileName(messege);
                                    // var name = file_name.split("_")[2];
                                }
                            return (
                                <ListGroup.Item key={i} onClick={()=> this.onMediaClick(i , chat.receiverid)} className={this.state.currentUser === i ? 'list-group-back' : null}>
                                    <div className="user--data">
                                        <span className="user--icon">
                                            <img src={show.image ? show.image : '/images/user.png'} alt="user" />
                                            <span className={`status--circle ${show?.is_online ? 'online--green' : 'offline--red'}`}></span>
                                        </span>
                                        <div className="user--name">
                                            <h5>{show?.first}{' '} {show?.last}</h5>
                                            <p> {deleted !== '0' 
                                                ? "Message removed" 
                                                : (is_url && messege.includes("video.thegalaxy.me")) 
                                                    ? 'Sent an attachment'
                                                    : messege
                                                } 
                                                {' '}
                                                <span>{chat.chat_messages.length && moment(chat.chat_messages[0].createdAt).fromNow()}</span></p>
                                        </div>
                                        {!check.includes(unseen_count)&&<div className='user--count'>{unseen_count}</div>}
                                    </div>
                                </ListGroup.Item>
                            )})
                        
                        :    <h6 className='text-center'>No Users to chat!</h6>
                   }
                   
                </ListGroup>
                <Modal 
                    show={this.state.show_modal} 
                    onHide={() => this.setState({ show_modal: false })} 
                    centered 
                    className='model-chat-main-wraper-mainn'
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Connect With People</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InvitationChatModel closeModel={() => this.setState({ show_modal: false })} updatedChatUsers={() => this.fetchChatUsers()}/>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { messenger , auth} = state;
    return {
        // chat_users: messenger.chat_users,
        current_chat_index: messenger.current_chat,
        unseen_count: messenger.unseen_count,
        userAuth : auth
    }
}


const mapDispatchToProps = dispatch => ({
    dispatch,
    ...bindActionCreators({
        getChatUsers,
        updateUnseenCount,
        setCurrentChatUser: payload => dispatch({ type: SET_CURRENT_CHAT_USER, payload }),

    }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatUsers);




