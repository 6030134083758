import React, { useState , useEffect} from 'react';
import { connect } from 'react-redux';
import { useSelector, useDispatch} from "react-redux";
import { Row, Col, Dropdown, ListGroup, Button, Form } from 'react-bootstrap';
import { BsHeart, BsReply, BsTrash, BsCardList, BsGridFill , BsPlay } from 'react-icons/bs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';
import { toast, Toaster } from "react-hot-toast";
import InfiniteScroll from 'react-infinite-scroller';
import { checkHTML , convertToPlainText , valid } from '../../utils/validations';
import LinkRender from '../Link/LinkRender';
import { BiPlus } from 'react-icons/bi';
import { FaPaperPlane, FaEllipsisV, FaRegComment } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { getProfilePosts } from '../../redux/actions/user.action';
import { decodedLoggedUser } from '../../helpers/auth';

function SearchPostsTab(props) {
    const [searchPostList , setSearchPostList] = useState('');
    const [isActive , setIsActive] = useState(true);
    const [loading , setLoading] = useState(false);
    const [userId , setUserId] = useState('')
    const auth = useSelector(state => state.auth);

    useEffect(()=>{
        if (auth.loggedIn) {
            let token = decodedLoggedUser();
            setUserId(token.aud)
        }

        if(props && props.post){
            setSearchPostList(props.post)
        }
    },[props])
   

    const renderSearchPosts = () =>{    
        if(searchPostList && searchPostList.length > 0 ) {
            return (
                <>
                    {searchPostList.map((item ,idx)=>{
                        if(item && item.PostCategory){
                            if(item.PostCategory.slug === 'audio'){
                                return renderAudio(item)
                            }
                            if(item.PostCategory.slug === 'text'){
                                return renderText(item)
                            }
                            if(item.PostCategory.slug === 'film'){
                                return renderVideo(item)

                            }
                            if(item.PostCategory.slug === 'visual'){
                                return renderVisual(item)

                            }
                            if(item.PostCategory.slug === 'Live-Video'){
                                return renderLiveVideo(item)
                            }
                        }    
                    })}
                </>
            )
        }

    }


    const renderLiveVideo = (video) =>{
        if(valid(video)){
            return (
                <Col sm={isActive ? '6' : '12'} lg={isActive ? '4' : '10'} className='mb-4'>
                    <div className="Gr--singleStream">
                        <div className="Gr--streamImage">
                            <span className='play--icon'><BsPlay/></span>
                            <Link to={`/live-video/${video.slug}`}>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={valid(video.thumbnail) ? video.thumbnail : '/images/thumbnail.jpg'}
                                    alt={video.name}
                                />
                            </Link>
                        </div>
                        <div className="Gr--streamDesc">    
                            <Link to={`/${video.user.username}`}>
                                <p>{video.user ? video.user.first: ''}</p>
                            </Link>
                        </div>
                        <div className="Gr--streamUser">
                            <Link to={`/live-video/${video.slug}`}>
                                <h4>{checkHTML(video.name) ? convertToPlainText(video.name) : video.name}</h4>
                            </Link>
                            <p>{moment(video.createdAt).format('LL')}</p>
                        </div>
                    </div>
                </Col>    
            )
        }
    }


    const renderAudio = (audio) =>{
        if(valid(audio)){
            let url = ''
            if (audio.Postmeta.length) {
                url = `/audio/album/${audio.slug}`;
            }
            else {
                url = `/audio/single/${audio.slug}`;
            }
            return (
                <Col sm={isActive ? '6' : '12'} lg={isActive ? '4' : '10'} className='mb-4'>
                    <div className="Gr--singleStream">
                         <div className="Gr--streamImage">
                            <Link to={url}>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={valid(audio.thumbnail) ? audio.thumbnail: '/images/thumb-400kb.jpg'}
                                    alt={audio.name}
                                />
                            </Link>
                         </div>
                         <div className="Gr--streamDesc">
                            <Link to={`/${audio.user.username}`}>
                                <p>{(audio.user && valid(audio.user.first))? audio.user.first : ''}</p>
                            </Link>
                         </div>
                         <div className="Gr--streamUser">
                            <Link to={url}>
                                <h4>{checkHTML(audio.name) ? convertToPlainText(audio.name) : audio.name}</h4>
                            </Link>
                            <p>{moment(audio.createdAt).format('LL')}</p>
                        
                         </div>  
                    </div>
                </Col>
            )
        }
    }


    const renderText = (text) =>{
        if(valid(text)){
            return(
                <Col sm={isActive ? '6' : '12'} lg={isActive ? '4' : '10'} className='mb-4'>
                    <div className="Gr--singleStream">
                        <div className="Gr--streamImage">
                            {valid(text.thumbnail) ?
                                <Link to={`/text/${text.slug}`}> 
                                    <LazyLoadImage
                                        width="100%"
                                        placeholderSrc="/images/placeholder.gif"
                                        effect="blur"
                                        src={text.thumbnail}
                                        alt={text.name}
                                    />
                                </Link>
                            :
                                <pre>{checkHTML(text.description) ? convertToPlainText(text.description) : text.description}</pre>    
                            }
                        </div>
                        <div className="Gr--streamDesc">
                            <Link to={`/${text.user.username}`}>
                                <p>{text.user ? text.user.first: ''}</p>
                            </Link>
                        </div>
                        <div className="Gr--streamUser">
                            <Link to={`/text/${text.slug}`}> 
                                <h4>{checkHTML(text.name) ? convertToPlainText(text.name) : text.name}</h4>
                            </Link>
                            <p>{moment(text.createdAt).format('LL')}</p>
                        </div>
                    </div>
                </Col>
            )
        }
    }


    const renderVideo = (video) =>{
        if(valid(video)){
             return (
                <Col sm={isActive ? '6' : '12'} lg={isActive ? '4' : '10'} className='mb-4'>
                    <div className="Gr--singleStream">
                        <div className="Gr--streamImage">
                            <span className='play--icon'><BsPlay/></span>
                            <Link to={`/video/${video.slug}`}>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={valid(video.thumbnail) ? video.thumbnail : '/images/thumbnail.jpg'}
                                    alt={video.name}
                                />
                            </Link>
                        </div>
                        <div className="Gr--streamDesc">    
                            <Link to={`/${video.user.username}`}>
                                <p>{video.user ? video.user.first: ''}</p>
                            </Link>
                        </div>
                        <div className="Gr--streamUser">
                            <Link to={`/video/${video.slug}`}>
                                <h4>{checkHTML(video.name) ? convertToPlainText(video.name) : video.name}</h4>
                            </Link>
                            <p>{moment(video.createdAt).format('LL')}</p>
                        </div>

                    </div>
                </Col>    
             )
        }
    }


    const renderVisual = (visual) =>{
        if(valid(visual)){
            return  (
                <Col sm={isActive ? '6' : '12'} lg={isActive ? '4' : '10'} className='mb-4'>
                    <div className="Gr--singleStream">
                        <div className="Gr--streamImage">
                            <Link to={`/visual/${visual.slug}`}>
                                <LazyLoadImage
                                    width="100%"
                                    placeholderSrc="/images/placeholder.gif"
                                    effect="blur"
                                    src={valid(visual.thumbnail)?visual.thumbnail :visual.Postmeta[0].meta_value}
                                    alt={visual.name}
                                />
                            </Link>
                        </div>
                        <div className="Gr--streamDesc">
                            <Link to={`/${visual.user.username}`}>
                                <p>{visual.user ? visual.user.first: ''}</p>
                            </Link>
                        </div>
                        <div className="Gr--streamUser">
                            <Link to={`/visual/${visual.slug}`}>
                                <h4>{checkHTML(visual.name) ? convertToPlainText(visual.name) : visual.name}</h4>
                            </Link>
                            <p>{moment(visual.createdAt).format('LL')}</p>
                        </div>
                    </div>    
                </Col>   
            )
        }
    }

    return (
        <>
            <Row className={isActive ? 'grid--view' : 'list--view'}>
                {!loading  ?
                   (searchPostList && searchPostList.length > 0)
                            
                    ?
                        <Col sm={12}>
                            <Row className={isActive ? 'justify-content-start' : 'justify-content-center'}>
                            {renderSearchPosts()}
                            </Row>
                        </Col>
                    :   <h2 className="mt-5 text-center">Posts Not Found</h2>  
                :   <Col>
                        <div className='text-center spinner--loader'></div>
                    </Col>   
                }           
            </Row>   
            

        </>
    );
   
}

export default SearchPostsTab ;

