import React, {useState , useEffect , useRef} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link,useParams,useNavigate} from "react-router-dom";
import { Container, Row, Col, Button, Dropdown, ListGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { IoMdHeart } from "react-icons/io";
import {BsArrowLeft} from 'react-icons/bs';
import { TbCalendarTime } from "react-icons/tb";
import { FaEllipsisV , FaRegHeart} from 'react-icons/fa';
import { toast, Toaster } from "react-hot-toast";
import moment from 'moment';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getSinglePost , clearPostData} from '../../redux/actions/post.action';
import { getVideoPosts } from '../../redux/actions/videoPost.action';
import { checkHTML , convertToPlainText , valid} from '../../utils/validations';
import {decodedLoggedUser ,likePosts} from '../../helpers/auth';
import './videoSingleStyle.css';
import '../../App.css';
import Subcriber from '../AntMedia/Subcriber';
import CopyToClipboard from 'react-copy-to-clipboard';
import { changePostStatus } from '../../redux/actions/post.action';
import LiveCommentsAnimation from '../Comments/LiveCommentsAnimation';
import { AddVideoPlayer , clearVideoPlayer , addVideoTimeStamp} from '../../redux/actions/videoPost.action';



function LiveVideoSingle() {
    const{slug} = useParams();  
    const [loading , setLoading] = useState(true);
    const [singlePost , setSinglePost] = useState('');
    const [relatedVideoList , setRelatedVideoList] = useState('');
    const [userId , setUserId] = useState('');
    const [isBActive, setBActive] = useState("false");
    const [isActive, setActive] = useState("false");
    const [commentList , setCommentsList] = useState('');
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.post);
    const stopLiveStream = useSelector(state=>state.post.updateLiveVideo)
    const apiError = useSelector(state => state.post);
    const auth = useSelector(state => state.auth);
    const relatedVideo = useSelector(state => state.videoPost.getVideoSuccess);
    const livePost  = useSelector(state => state.liveVideo)
    const postStatus = useSelector(state => state.post.changeStatusSuccess);
    const navigate = useNavigate();
    const videoRef = useRef(null);

    useEffect(()=>{
        if (auth.loggedIn) {
            let token = decodedLoggedUser();
            setUserId(token.aud)
        }
        dispatch(getSinglePost(slug));
        dispatch(clearVideoPlayer())
        const data = {status:'1',perPage:12,page:1};
        dispatch(getVideoPosts(data));
        return(()=>{
            dispatch(clearPostData());
        })
    },[]);

    useEffect(()=>{
        if(postStatus && postStatus.success){
            if( postStatus.message.trim() === 'Post deleted'){
                navigate('/')
            }else{
                navigate('/drafts')
            }
        }
    },[postStatus])

    useEffect(()=>{
        if(livePost && livePost.liveVideoUpdated){
            if(livePost.liveVideoUpdated && livePost.liveVideoUpdated.success){
                if(livePost.liveVideoUpdated.post.slug === singlePost.slug){
                    setSinglePost(livePost.liveVideoUpdated.post)
                    setLoading(false)
                }
            }
        } 
    },[livePost])


    useEffect(()=>{
        if(relatedVideo && relatedVideo.success){
            setRelatedVideoList(relatedVideo.videoPostList.liveVideoPost)
        }
        if(apiResult?.singlePostSuccess && apiResult?.singlePostSuccess.Post){
            dispatch(AddVideoPlayer(apiResult?.singlePostSuccess?.Post))
            setSinglePost(apiResult?.singlePostSuccess?.Post)
            setLoading(false)
        }
        if(apiResult?.singlePostSuccess && apiResult?.singlePostSuccess.commentList){
            setCommentsList(apiResult?.singlePostSuccess.commentList);
            setLoading(false)
        }
    },[apiResult,relatedVideo]);

    useEffect(()=>{
        if(apiError && apiError.singlePostError){
            setLoading(false);
            // toast.error(apiError.singlePostError.message, {
            //     position: 'top-center', 
            // });
        }
    },[apiError])
  
    const handleToggle = () => {
        setActive(!isActive);
    };

    const handleShowToggle = () => {
        setBActive(!isBActive);
        setActive(!isActive);
    };
    const  handleLike = async (data) => {
        // if(Number(singlePost.user.id) !== Number(userId)){
            likePosts(userId, data.id)
        // }
    }

    const handleCommentsLength = (comments) =>{

        if(comments && comments.length > 0){

            const activeComments = comments.filter((item , index)=>{
                if(item.status === '1'){
                    return item
                }
            })
            if(activeComments && activeComments.length >0){
                return (
                    <small className='ms-2'>{activeComments.length}</small>
                )
            }
        }
    }


    


    const updateCommentList = () =>{
        dispatch(getSinglePost(slug));
    }

    const renderUserAction = ()=>{
        if (singlePost.user && singlePost.user.id == userId){
            return (
                <Dropdown className='edit--post--dropdown'>
                    <Dropdown.Toggle id="dropdown-basic">
                        <FaEllipsisV />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {/* <Link className='dropdown-item' to='/live' state={{post:singlePost.slug}}>Edit</Link> */}
                        <Link className='dropdown-item' to={`/edit-video/${singlePost.slug}`}>Edit</Link>
                        <Dropdown.Item>
                            <CopyToClipboard text={window.location.href} onCopy={()=>{handleCopy()}}>
                                <>Share Link</>
                            </CopyToClipboard>   
                        </Dropdown.Item>
                        <Dropdown.Item onClick={()=>handleChangePostStatus(singlePost.id,'2')}>Move to Drafts</Dropdown.Item>
                        <Dropdown.Item onClick={()=>handleChangePostStatus(singlePost.id,'0')}>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            )
        }
    }

    const handleCopy = () => {
        toast.success('Link copy to clipboard', {position: 'top-center'});
    };


    const handleChangePostStatus = async(postId , status) =>{
        const data = {postId:postId , status:status}
        dispatch(clearPostData());
        dispatch(changePostStatus(data))
    }

    const handleTimeUpdate = () => {
        const currentTime = videoRef.current.currentTime;
        const data = {postId :singlePost.id , time:currentTime}
        dispatch(addVideoTimeStamp(data))
    };


    const handleLikeLength = (post) =>{
        if(post){
            const postUserId = post.user_id

            const userFound = post.Likes.some(like => Number(like.user_id) === Number(postUserId))

            if(userFound){
                if(post.Likes && post.Likes.length > 0){
                    const length =  post.Likes.length-1 ;
                    if(length === 0){
                        return null
                    }else{
                        return length
                    }
                }
            }else{
                return post.Likes.length ;
            }
        }
    }


    return (
        <>
            <PageHeader />
                <div className='page--content'>
                    <SidebarMenu />
                <div className='discover--module common--wrapper video--single--page'>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <Link className='back--btn' onClick={()=>navigate(-1)}><BsArrowLeft />Back</Link>
                            </Col>
                            {!loading ?
                                valid(singlePost) ?
                                <>
                                {singlePost.action === 'online' ?
                                    <Col sm={12} lg={8}>
                                        {(Number(userId) !== Number(singlePost.user.id) && singlePost.user.booked_schedulers && singlePost.user.booked_schedulers.length > 0)&&
                                                <div className='calendar--icon'>
                                                    <OverlayTrigger
                                                        key='top'
                                                        placement='top'
                                                        overlay={<Tooltip id={`tooltip-top`}>Schedule a Call</Tooltip>}
                                                        >
                                                        <Button variant='primary'><TbCalendarTime onClick={()=>navigate(`/book-appointments/${singlePost.user.id}`)}/></Button>
                                                    </OverlayTrigger>
                                                </div>
                                        }
                                        <div className='single--bg--col'>
                                            <div className='video--user'>
                                                <Link to={`/${singlePost.user.username}`}>
                                                    <div className='video--user--name'>
                                                        <img src={singlePost.user.image ||'/images/default-avtaar.png'} alt={singlePost.user.first} />
                                                        <h5>{(singlePost.user && valid(singlePost.user.first))? singlePost.user.first : ''}<span>{moment(singlePost.createdAt).format('LL')}</span></h5>
                                    
                                                    </div>
                                                </Link>
                                                {renderUserAction()}
                                            </div>
                                            <div className='video--desc mt-3 mb-3 ps-3'>
                                                <p className='mb-0'>{checkHTML(singlePost.description) ? convertToPlainText(singlePost.description) : singlePost.description}</p>
                                            </div> 
                                            <div className='video--block'>
                                                {singlePost.schedule_post ?
                                                    <Button variant="light" className="schedule--button">
                                                        <span className="d-block"> Scheduled </span>
                                                        <span className="scheduleDate">{moment(singlePost.schedule_date).format("MM/DD/YYYY LT")}</span>
                                                    </Button> 
                                                    : null
                                                }    
                                                {singlePost.action === 'online' 
                                                    ?   <Subcriber streamId={singlePost.addPost}/>
                                                    :  
                                                    <>
                                                        <video preload="auto"  ref={videoRef} src={singlePost.media}  poster={singlePost.thumbnail || ''} controls autoPlay onTimeUpdate={handleTimeUpdate}/>
                                                    </>
                                                        
                                                }
                                            </div> 
                                            <div className='liveVideoSingle--likeBtn'>
                                                <ListGroup>
                                                    <ListGroup.Item onClick = {()=>handleLike(singlePost)}>
                                                        {(auth.loggedIn)&&
                                                            <>
                                                                <span className='me-2'>
                                                                    {(singlePost?.Likes && singlePost?.Likes.length > 0 ) && handleLikeLength(singlePost)}
                                                                </span>
                                                                {singlePost?.Likes?.some(like => like?.user_id === userId) ? (
                                                                <IoMdHeart />) : (<FaRegHeart /> )}
                                                            </>
                                                        }
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </div>
                                            
                                        </div>
                                    </Col>
                                
                                :
                                    <Col sm={12} lg={8}className=' mx-auto' >
                                        {(Number(userId) !== Number(singlePost.user.id) && singlePost.user.booked_schedulers && singlePost.user.booked_schedulers.length > 0)&&
                                                <div className='calendar--icon'>
                                                    <OverlayTrigger
                                                        key='top'
                                                        placement='top'
                                                        overlay={<Tooltip id={`tooltip-top`}>Schedule a Call</Tooltip>}
                                                        >
                                                        <Button variant='primary'><TbCalendarTime onClick={()=>navigate(`/book-appointments/${singlePost.user.id}`)}/></Button>
                                                    </OverlayTrigger>
                                                </div>
                                        }
                                        <div className='single--bg--col'>
                                            <div className='video--user'>
                                                <Link to={`/${singlePost.user.username}`}>
                                                    <div className='video--user--name'>
                                                        <img src={singlePost.user.image ||'/images/default-avtaar.png'} alt={singlePost.user.first} />
                                                        <h5>{(singlePost.user && valid(singlePost.user.first))? singlePost.user.first : ''}<span>{moment(singlePost.createdAt).format('LL')}</span></h5>
                                    
                                                    </div>
                                                </Link>
                                                {renderUserAction()}
                                            </div>
                                            <div className='video--desc mt-3 mb-3 ps-3'>
                                                <p className='mb-0'>{checkHTML(singlePost.description) ? convertToPlainText(singlePost.description) : singlePost.description}</p>
                                            </div> 
                                            <div className='video--block'>
                                                {singlePost.schedule_post ?
                                                    <Button variant="light" className="schedule--button">
                                                        <span className="d-block"> Scheduled </span>
                                                        <span className="scheduleDate">{moment(singlePost.schedule_date).format("MM/DD/YYYY LT")}</span>
                                                    </Button> 
                                                    : null
                                                }    
                                                {singlePost.action === 'online' 
                                                    ?   <Subcriber streamId={singlePost.addPost}/>
                                                    :  
                                                    <>
                                                        <video preload="auto"  src={singlePost.media}  poster={singlePost.thumbnail || ''} controls autoPlay/>
                                                    </>
                                                        
                                                }
                                            </div>
                                            <div className='liveVideoSingle--likeBtn'>
                                            <ListGroup>
                                                <ListGroup.Item onClick = {()=>handleLike(singlePost)}>
                                                    {(auth.loggedIn)&&
                                                        <>
                                                            <span className='me-2'>
                                                                {(singlePost?.Likes && singlePost?.Likes.length > 0 ) && handleLikeLength(singlePost)}
                                                            </span>
                                                            {singlePost?.Likes?.some(like => like?.user_id === userId) ? (
                                                            <IoMdHeart />) : (<FaRegHeart /> )}
                                                        </>
                                                    }
                                                </ListGroup.Item>
                                            </ListGroup>
                                            </div>
                                           
                                        </div>
                                    </Col>
                                }

                                    {singlePost.action === 'online' 
                                        ?
                                            <Col sm={12} lg={4}>
                                                {(singlePost.commenting && auth.loggedIn) &&
                                                    <LiveCommentsAnimation updateComment={()=>updateCommentList()} postId = {singlePost}/>
                                                } 
                                            </Col>
                                        :
                                        <Col sm={12} lg={8} className='mt-4 mx-auto'>
                                            {(singlePost.commenting && auth.loggedIn) &&
                                                <LiveCommentsAnimation updateComment={()=>updateCommentList()} postId = {singlePost}/>
                                            } 
                                        </Col>
                                    }
                                </>    
                                :  <Col sm={12}>
                                        <h2> Posts Not Found </h2>
                                    </Col>    
                            :
                                <Col sm={12}>
                                    <div className='text-center spinner--loader'></div>
                                </Col>    
                            }        
                        </Row>
                    </Container>
                </div>
                <Toaster/>
            </div>
        </>
    );
}

export default LiveVideoSingle;