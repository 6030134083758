import {useState} from 'react'
import { Link } from 'react-router-dom';
import { useSelector} from "react-redux";
import {Col,ListGroup,Dropdown} from 'react-bootstrap';
import { FaEllipsisV , FaRegHeart , FaRegCalendarAlt , FaRegUser , FaPlay} from 'react-icons/fa';
import { BiCommentDetail} from 'react-icons/bi';
import { IoMdHeart } from "react-icons/io";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import { valid, checkHTML , convertToPlainText  } from '../../utils/validations';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LinkRender from '../Link/LinkRender';


function StreamDesignView(props) {

    const [visiblePosts ,setVisblePost] = useState([]);
    const auth = useSelector(state => state.auth);

    const renderStreamList = () =>{
        if(props.post && props.post.PostCategory){
            if(props.post.PostCategory.slug === 'audio'){
                return renderAudio(props.post)
            }
            if(props.post.PostCategory.slug === 'text'){
                return renderText(props.post)
            }
            if(props.post.PostCategory.slug === 'film'){
                return renderVideo(props.post)
            }
            if(props.post.PostCategory.slug === 'visual'){
               return  renderVisual(props.post)
            }
            if(props.post.PostCategory.slug === 'Live-Video'){
                return renderVideo(props.post)
            }
        }    
    }


    const handleLikeLength = (post) =>{
        if(post){
            const postUserId = post.user_id

            const userFound = post.Likes.some(like => Number(like.user_id) === Number(postUserId))

            if(userFound){
                if(post.Likes && post.Likes.length > 0){
                    const length =  post.Likes.length-1 ;
                    if(length === 0){
                        return null
                    }else{
                        return length
                    }
                }
            }else{
                return post.Likes.length ;
            }
        }
    }

    const renderText = (post) =>{
        if(valid(post)){
            if(props.isActive){
                return (
                    <Col  sm={props.isActive ? '6' : '12'} xl={props.isActive ? '4' : '8'} className='mb-4'>
                        <div className="Gr--singleStream">
                            <Link to={`/text/${post.slug}`}>
                                {valid(post.thumbnail) ?
                                    <div className="Gr--streamImage">
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={post.thumbnail} 
                                            alt={post.name}
                                        /> 
                                    </div>
                                    :
                                    <div className="Gr--streamImage">
                                        <Link to={`/text/${post.slug}`}>
                                            <pre>{checkHTML(post.description) ? convertToPlainText(post.description) : post.description}</pre>
                                        </Link>
                                    </div>
                                }
                                <div className="Gr--streamDesc">
                                    <Link to={`/${post.user.username}`} >
                                        <img src={(post.user && valid(post.user.image)) ? post.user.image : "/images/user.png"} alt='...' />
                                    </Link>
                                    <div className="Gr--streamUser">
                                        <h4><Link to={`/text/${post.slug}`}>{checkHTML(post.name) ? convertToPlainText(post.name) : post.name}</Link></h4>
                                        <Link to={`/${post.user.username}`}>
                                            <p><FaRegUser/> {(post.user && valid(post.user.first))? post.user.first : ''}</p>
                                        </Link>
                                        <p><FaRegCalendarAlt /> {moment(post.createdAt).format('LL')}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </Col>
                )
            }else{
                return (
                    <Col sm="12" lg="9">
                        <div className='single--stream--post mt-4'>
                            <div className="Gr--stream-user">
                                {(props.userId == post.user.id ) &&
                                    <Dropdown className='edit--post--dropdown'>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <FaEllipsisV />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Link className='dropdown-item' to={`/edit-text/${post.slug}`}>Edit</Link>
                                            <Dropdown.Item>
                                                <CopyToClipboard text={handleCopy(post)} onCopy={()=>{props.copyText()}}>
                                                    <p>Share Link</p>
                                                </CopyToClipboard>   
                                            </Dropdown.Item>
                                            <Link className='dropdown-item' onClick={()=>props.handleChangePostStatus(post.id , '2')}>Move to Drafts</Link>
                                            <Link className='dropdown-item' onClick={()=>props.handleChangePostStatus(post.id , '0')}>Delete</Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                                <div className="Gr--streamDesc">
                                    <Link to={`/${post.user.username}`}>
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={(post.user && valid(post.user.image)) ? post.user.image : "/images/user.png"}
                                            alt={post.name}
                                        />
                                    </Link>
                                </div>
                                <div className="Gr--streamUser">
                                    <Link to={`/${post.user.username}`}>
                                        <h4>{(post.user && valid(post.user.first))? post.user.first : ''}</h4>
                                        <p><span>{moment(post.createdAt).format('LL')}</span> </p>
                                    </Link>
                                </div>
                            </div>
                            <div className='Gr--streamPost--content contextonlywrap'>
                                <h2 className='mt-3'>{checkHTML(post.name) ? convertToPlainText(post.name) : post.name}</h2>
                                {valid(post.thumbnail) ?
                                    <Link to={`/text/${post.slug}`}>
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={post.thumbnail} 
                                            alt={post.name}
                                        /> 
                                    </Link>
                                                        
                                :
                                    <>
                                        <Link to={`/text/${post.slug}`}>
                                            <div  className='streamDec' dangerouslySetInnerHTML={{ __html: post.description}} />
                                        </Link>
                                    </>
                                }
                            </div>
                            {post.media !=='' 
                                ? 
                                
                                    <LinkRender post= {post}/>
                                : null
                            }
                            <div className='stream--postActions'>
                                <ListGroup>
                                    {(auth.loggedIn)&&
                                        <ListGroup.Item  >
                                            {(post.Likes && post.Likes.length > 0) && <span className='me-2'>{handleLikeLength(post)}</span>}
                                            {post.Likes.some(like => like.user_id === props.userId) ? (
                                                <IoMdHeart  onClick = {()=>props.handleLike(post)}/>) : (<FaRegHeart onClick = {()=>props.handleLike(post)}/>
                                            )}
                                        </ListGroup.Item>
                                    }
                                    
                                    <ListGroup.Item className='ms-auto'>
                                    
                                        {post.commenting && 
                                            <>
                                            <Link to={`/text/${post.slug}`}>
                                                    <BiCommentDetail/>
                                                    <span className='ms-2'>
                                                        {(post.comments && post.comments.length > 0)&&
                                                            props.handleCommentsLength(post.comments)
                                                        }
                                                    </span>
                                                </Link>
                                            </>
                                        }    
                                    </ListGroup.Item>
                                        
                                    </ListGroup>
                            </div>
                        </div>
                    </Col>
                )
            }    
        }

    }


    const isPostVisible = (postId) => visiblePosts.includes(postId);

    const toggleVisibility = (postId) => {
        if (visiblePosts.includes(postId)) {
            setVisblePost(visiblePosts.filter((id) => id !== postId));
        } else {
            setVisblePost([...visiblePosts, postId]);
        }
    };


    const handleCopy =(post) => {
        if(post.PostCategory.slug === 'text'){
            let link = `${window.location.origin}/text/${post.slug}`;
            return link

        }
        if(post.PostCategory.slug === 'film'){
            let link = `${window.location.origin}/video/${post.slug}`;
            return link

        }
        if(post.PostCategory.slug === 'audio'){
            if (post.Postmeta.length) {
                let link = `${window.location.origin}/audio/album/${post.slug}`;
                return link
                
            }
            else {
                let link = `${window.location.origin}/audio/single/${post.slug}`;
                return link
            }
        }
        if(post.PostCategory.slug === 'visual'){
            let link = `${window.location.origin}/visual/${post.slug}`;
            return  link
            
        }
        if(post.PostCategory.slug === 'Live-Video'){
            let link = `${window.location.origin}/Live-Video/${post.slug}`;
            return  link
        }
        if(post.PostCategory.slug === 'link'){
            let link = post.media
            return link
        }
       
    }

    const renderVisual = (visual) =>{
        if(valid(visual)){
            let url = `/visual/${props.post.slug}`;
            if(props.isActive){
                return (
                    <Col  sm={props.isActive ? '6' : '12'} xl={props.isActive ? '4' : '8'} className='mb-4'>
                        <div>
                            <div className="Gr--singleStream">
                                <div className="Gr--streamImage">
                                    <Link to={url}>
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={visual.thumbnail || visual.Postmeta[0].meta_value }
                                            alt={visual.name}
                                        /> 
                                    </Link>
                                </div>
                                <div className="Gr--streamDesc">
                                    <Link to={`/${visual.user.username}`}>
                                        <img src={(visual.user && valid(visual.user.image)) ? visual.user.image : "/images/user.png"} alt='...' />
                                    </Link>
                                    <div className="Gr--streamUser">
                                        <h4><Link to={url}>{checkHTML(visual.name) ? convertToPlainText(visual.name) : visual.name}</Link></h4>
                                        <Link to={`/${visual.user.username}`}>
                                            <p><FaRegUser/> {(visual.user && valid(visual.user.first))? visual.user.first : ''}</p>
                                        </Link>
                                        <p><FaRegCalendarAlt /> {moment(visual.createdAt).format('LL')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                )
            }else{    
                return (
                    <Col  sm="12" lg="9">
                        <div className='single--stream--post mt-4'>
                            <div className="Gr--stream-user">
                                {props.userId == visual.user.id &&
                                    <Dropdown className='edit--post--dropdown'>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <FaEllipsisV /> 
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Link className='dropdown-item' to={`/edit-visual/${visual.slug}`}>Edit</Link>
                                            <Dropdown.Item>
                                                <CopyToClipboard  text={handleCopy(visual)} onCopy={()=>{props.copyText()}}>
                                                    <p>Share Link</p>
                                                </CopyToClipboard>   
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={()=>props.handleChangePostStatus(visual.id,'2')}>Move to Drafts</Dropdown.Item>
                                            <Dropdown.Item onClick={()=>props.handleChangePostStatus(visual.id,'0')}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                                <div className="Gr--streamDesc">
                                    <Link to={`/${visual.user.username}`}>
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={(visual.user && valid(visual.user.image)) ? visual.user.image : "/images/user.png"}
                                            alt={visual.name}
                                        />
                                    </Link>
                                </div>
                                <div className="Gr--streamUser">
                                    <Link to={`/${visual.user.username}`}>
                                        <h4>{(visual.user && valid(visual.user.first))? visual.user.first : ''}</h4>
                                        <p><span>{moment(visual.createdAt).format('LL')}</span> </p>
                                    </Link>
                                </div>
                            </div>
                            <div className='Gr--streamPost--content'>
                                <Link to={`/visual/${visual.slug}`}>
                                    <LazyLoadImage
                                        width="100%"
                                        placeholderSrc="/images/placeholder.gif"
                                        effect="blur"
                                        src={visual.thumbnail || visual.Postmeta[0].meta_value }
                                        alt={visual.name}
                                    /> 
                                </Link>
                            </div>
                            <h2 className='mt-3'>{checkHTML(visual.name) ? convertToPlainText(visual.name) : visual.name}</h2>
                            {checkHTML(visual.description) ? 
                                <div  className='txtWrap mb-0' dangerouslySetInnerHTML={{ __html: visual.description }} />
                                :
                                <p className='mb-0 txtWrap'>{visual.description}</p>
                            }
                            <div className='stream--postActions'>
                                <ListGroup>
                                    {(auth.loggedIn)&&
                                        <ListGroup.Item >
                                            {(visual.Likes && visual.Likes.length > 0) && <span className='me-2'>{handleLikeLength(visual)}</span>}
                                            {visual.Likes.some(like => like.user_id === props.userId) ? (
                                                <IoMdHeart  onClick = {()=>props.handleLike(visual)}/>) : (<FaRegHeart onClick = {()=>props.handleLike(visual)}/>
                                            )}
                                        </ListGroup.Item>
                                    }
                                    <ListGroup.Item className='ms-auto'>
                                        {visual.commenting && 
                                            <>
                                                <Link to={`/visual/${visual.slug}`}>
                                                    <BiCommentDetail/>
                                                    <span className='ms-2'>
                                                        {(visual.comments && visual.comments.length > 0)&&
                                                            props.handleCommentsLength(visual.comments)
                                                        }
                                                    </span>
                                                </Link>
                                            </>
                                        }    
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>
                    </Col>
                )
            }
        }
    }


    const renderAudio = (audio) =>{
        if(valid(audio)){
            if (audio.Postmeta.length) {
                var url = '';
                url = `/audio/album/${audio.slug}`;
            }
            else {
                url = `/audio/single/${audio.slug}`;
            }
            if(props.isActive){
                return (
                    <Col  sm={props.isActive ? '6' : '12'} xl={props.isActive ? '4' : '8'} className='mb-4'>
                        <div>
                            <div className="Gr--singleStream">
                                <div className="Gr--streamImage">
                                    <Link to={url}>
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={valid(audio.thumbnail) ? audio.thumbnail: '/images/thumb-400kb.jpg'}
                                            alt={audio.name}
                                        /> 
                                    </Link>
                                </div>  
                                <div className="Gr--streamDesc">
                                    <Link to={`/${audio.user.username}`}>
                                        <img src={(audio.user && valid(audio.user.image)) ? audio.user.image : "/images/user.png"} alt='...' />
                                    </Link>
                                    <div className="Gr--streamUser">
                                        <h4><Link to={url}>{checkHTML(audio.name) ? convertToPlainText(audio.name) : audio.name}</Link></h4>
                                        <Link to={`/${audio.user.username}`}>
                                            <p><FaRegUser/> {(audio.user && valid(audio.user.first))? audio.user.first : ''}</p>
                                        </Link>
                                        <p><FaRegCalendarAlt /> {moment(audio.createdAt).format('LL')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                )
            }else{
                return (
                    <Col  sm="12" lg="9">
                        <div className='single--stream--post mt-4'>
                            <div className="Gr--stream-user">
                                {props.userId == audio.user.id &&
                                    <Dropdown className='edit--post--dropdown'>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <FaEllipsisV />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Link className='dropdown-item' to={`/edit${url}`}>Edit</Link>
                                            <Dropdown.Item>
                                                <CopyToClipboard text={handleCopy(audio)} onCopy={()=>{props.copyText()}}>
                                                    <p>Share Link</p>
                                                </CopyToClipboard>   
                                            </Dropdown.Item>
                                            <Link className='dropdown-item' onClick={()=>props.handleChangePostStatus(audio.id , '2')}>Move to Drafts</Link>
                                            <Link className='dropdown-item' onClick={()=>props.handleChangePostStatus(audio.id , '0')}>Delete</Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                                <div className="Gr--streamDesc">
                                    <Link to={`/${audio.user.username}`}>
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={(audio.user && valid(audio.user.image)) ? audio.user.image : "/images/user.png"}
                                            alt={audio.name}
                                        />
                                    </Link>
                                </div>
                                <div className="Gr--streamUser">
                                    <Link to={`/${audio.user.username}`} >
                                        <h4>{(audio.user && valid(audio.user.first))? audio.user.first : ''}</h4>
                                    </Link>    
                                        <p className='mb-0 txtWrap'><span>{moment(audio.createdAt).format('LL')}</span> </p>
                                
                                </div>
                            </div>
                            <div className='Gr--streamPost--content'>
                                <Link to={url}>
                                    <LazyLoadImage
                                        width="100%"
                                        placeholderSrc="/images/placeholder.gif"
                                        effect="blur"
                                        src={valid(audio.thumbnail) ? audio.thumbnail: '/images/thumb-400kb.jpg'}
                                        alt={audio.name}
                                    /> 
                                </Link>
                            </div>
                            <h2 className='mt-3'>{checkHTML(audio.name) ? convertToPlainText(audio.name) : audio.name}</h2>
                            {checkHTML(audio.description) ? 
                                <div  className='mb-0 txtWrap' dangerouslySetInnerHTML={{ __html: audio.description }} />
                                :
                                <p className='mb-0 txtWrap'>{audio.description}</p>
                            }
                            <div className='stream--postActions'>
                                <ListGroup>
                                    {(auth.loggedIn)&&
                                        <ListGroup.Item >
                                            {(audio .Likes && audio.Likes.length > 0) && <span className='me-2'>{handleLikeLength(audio)}</span>}
                                            {audio.Likes.some(like => like.user_id === props.userId) ? (
                                                <IoMdHeart  onClick = {()=>props.handleLike(audio)}/>) : (<FaRegHeart onClick = {()=>props.handleLike(audio)}/>
                                            )}
                                        </ListGroup.Item>
                                    }
                                    <ListGroup.Item className='ms-auto'>
                                        {audio.commenting && 
                                            <>
                                                <Link to={url}>
                                                    <BiCommentDetail/>
                                                    <span className='ms-2'>
                                                        {(audio.comments && audio.comments.length > 0)&&
                                                            props.handleCommentsLength(audio.comments)
                                                        }
                                                    </span>
                                                </Link>
                                            </>
                                        }    
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>
                    </Col>
                )
            }
        }

    }

    const renderVideo = (video) => {
        if(valid(video)){
            let url = '';
                if (props.post.PostCategory.slug === 'Live-Video') {
                    url = `/live-video/${props.post.slug}`;
                }
                else {
                    url = `/video/${props.post.slug}`;
                }
            if(props.isActive){
                return(
               
                    <Col  sm={props.isActive ? '6' : '12'} xl={props.isActive ? '4' : '8'} className='mb-4'>
                        <div>
                            <div className="Gr--singleStream">
                                <div className="Gr--streamImage">
                                    <Link to={url}>
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={valid(video.thumbnail) ? video.thumbnail : '/images/thumbnail.jpg'} 
                                            alt={checkHTML(video.name) ? convertToPlainText(video.name) : video.name}
                                        /> 
                                    </Link>
                                </div>
                                <div className="Gr--streamDesc">
                                    <Link to={url} state={{user:video.user.id}}>
                                        <img src={(video.user && valid(video.user.image)) ? video.user.image : "/images/user.png"} alt='...' />
                                    </Link>
                                    <div className="Gr--streamUser">
                                        <h4><Link to={url}>{checkHTML(video.name) ? convertToPlainText(video.name) : video.name}</Link></h4>
                                        <Link to={`/${video.user.username}`}>
                                            <p><FaRegUser/> {(video.user && valid(video.user.first))? video.user.first : ''}</p>
                                        </Link>
                                        <p><FaRegCalendarAlt /> {moment(video.createdAt).format('LL')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                )

            }else{
                return (
                    <Col sm="12" lg="9">
                        <div className='single--stream--post mt-4'>
                            <div className="Gr--stream-user">
                                {props.userId == video.user.id &&
                                    <Dropdown className='edit--post--dropdown'>
                                        <Dropdown.Toggle id="dropdown-basic">
                                            <FaEllipsisV />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                        <Link className='dropdown-item' to={`/edit-video/${video.slug}`}>Edit</Link>
                                            <Dropdown.Item>
                                                <CopyToClipboard text={handleCopy(video)} onCopy={()=>{props.copyText()}}>
                                                    <p>Share Link</p>
                                                </CopyToClipboard>   
                                            </Dropdown.Item>
                                            <Link className='dropdown-item' onClick={()=>props.handleChangePostStatus(video.id , '2')}>Move to Drafts</Link>
                                            <Link className='dropdown-item' onClick={()=>props.handleChangePostStatus(video.id , '0')}>Delete</Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                                <div className="Gr--streamDesc">
                                    <Link to={`/${video.user.username}`}>
                                        <LazyLoadImage
                                            width="100%"
                                            placeholderSrc="/images/placeholder.gif"
                                            effect="blur"
                                            src={(video.user && valid(video.user.image)) ? video.user.image : "/images/user.png"}
                                            alt={video.name}
                                        />
                                    </Link>
                                </div>
                                <div className="Gr--streamUser">
                                    <Link to={`/${video.user.username}`}>
                                        <h4>{(video.user && valid(video.user.first))? video.user.first : ''}</h4>
                                        <p><span>{moment(video.createdAt).format('LL')}</span> </p>
                                    </Link>
                                </div>
                            </div>
                            <div className='Gr--streamPost--content'>
                                <Link to={`/video/${video.slug}`}>
                                    <LazyLoadImage
                                        width="100%"
                                        placeholderSrc="/images/placeholder.gif"
                                        effect="blur"
                                        src={valid(video.thumbnail) ? video.thumbnail : '/images/thumbnail.jpg'} 
                                        alt={video.name}
                                    /> 
                                </Link>
                            </div>
                            <div class="Gr--streamPost--content contextonlywrap">
                                <Link to={`/live-video/${video.slug}`}>
                                    <h2 className='mt-3'>{checkHTML(video.name) ? convertToPlainText(video.name) : video.name}</h2>
                                </Link>
                                {video.description && video.description.length > 600 ?
                                    <>
                                    
                                        <div  className={isPostVisible(video.id) ? 'streamDec' : 'streamDec streamDecShow'} dangerouslySetInnerHTML={{ __html: video.description}} />
                                    
                                        <a className='showHidebtn' onClick={()=>toggleVisibility(video.id)}>{isPostVisible(video.id) ? 'Show Less' : 'Show More '}</a>
                                    </>
                                    :
                                        <div  className='streamDec' dangerouslySetInnerHTML={{ __html: video.description}} />
                                }
                            </div>
                            <div className='stream--postActions'>
                                <ListGroup>
                                    {(auth.loggedIn)&&
                                        <ListGroup.Item >
                                            {(video.Likes && video.Likes.length > 0) && <span className='me-2'>{handleLikeLength(video)}</span>}
                                            {video.Likes.some(like => like.user_id === props.userId) ? (
                                                <IoMdHeart  onClick = {()=>props.handleLike(video)}/>) : (<FaRegHeart onClick = {()=>props.handleLike(video)}/>
                                            )}
                                        </ListGroup.Item>
                                    }
                                    <ListGroup.Item className='ms-auto'>
                                        {video.commenting && 
                                            <>
                                                <Link to={`/video/${video.slug}`}>
                                                    <BiCommentDetail/>
                                                    <span className='ms-2'>
                                                        {(video.comments && video.comments.length > 0)&&
                                                            props.handleCommentsLength(video.comments)
                                                        }
                                                    </span>
                                                </Link>
                                            </>
                                        }    
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Link to={`/video/${video.slug}`}>
                                            <FaPlay className='ms-2' />
                                        </Link>
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>
                    </Col>
                )
            }
        }

    }

  return (
        <>
            {renderStreamList()}
        </>
  )
}

export default StreamDesignView