import React, {useState , useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams , useLocation} from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { BsCardList, BsGridFill , BsPlay} from 'react-icons/bs';
import { FaEllipsisV, FaRegUser, FaRegCalendarAlt } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Container, Row, Col, ListGroup, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import { getSingleBoardPostList } from '../../redux/actions/post.action';
import {decodedLoggedUser} from '../../helpers/auth';
import { checkHTML , convertToPlainText , valid} from '../../utils/validations';
import { changePostStatus } from '../../redux/actions/post.action';
import LinkRender from '../Link/LinkRender';
import PageHeader from '../Header/HeaderTop';
import SidebarMenu from '../Sidebar/SidebarNav';
import { likePosts } from '../../helpers/auth';
import StreamDesignView from '../DesignView/StreamDesignView';
import 'bootstrap/dist/css/bootstrap.min.css';
import './realmStyle.css';
import '../../App.css';


function RealmDisclosure() {
    const{slug} = useParams();  
    const location = useLocation();
    const [loading , setLoading] = useState(true);
    const [realmPosts , setRealmPosts] = useState('');
    const [realmCat , setRealmCat] = useState('');
    const [isActive, setActive] = useState(false);
    const [userId , setUserId] = useState('')
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.post.singleBoardPostList);
    const apiError = useSelector(state => state.post.singleBoardPostError);
    const auth = useSelector (state =>  state.auth);
    const postStatus= useSelector(state => state.post.changeStatusSuccess);
    const postStatusError = useSelector(state => state.post.changeStatusError);
    const navigate =  useNavigate();
    // useEffect(()=>{
    //     if(postStatus && postStatus.success){
    //         dispatch(getSingleBoardPostList(location?.state?.slug));
    //     }
    // },[postStatus])
  
    useEffect(()=>{
        if (auth.loggedIn) {
            let token = decodedLoggedUser()
            setUserId(token.aud)
        }
        if(location?.state && location?.state?.slug){
            // setSlug(location?.state?.slug)
            dispatch(getSingleBoardPostList(location?.state?.slug));
        }
    },[slug]);


    useEffect(()=>{
        if(apiResult && apiResult.success){
            setRealmPosts(apiResult.singleBoardPosts);
            setRealmCat(apiResult.realmCat)
            setLoading(false)
        }
        filterPosts()
    },[apiResult])

    useEffect(()=>{
        if(apiError && apiError.message){
            toast.error(apiError.message, {
                position: 'top-center', 
            });
            setLoading(false)
        }
    },[apiError])

    const handleToggle = () => {
        setActive(!isActive);
    };


/**   using for filter the post by  using slug like audio , video , text , link  */
    const filterPosts = () =>{
        if(realmPosts && realmPosts.length > 0){
            return(
                <>
                    {
                        realmPosts.map((item , idx)=>{
                            return(
                                <StreamDesignView
                                    post={item}
                                    userId = {userId}
                                    copyText = {copyText}
                                    handleChangePostStatus = {handleChangePostStatus}
                                    handleCommentsLength = {handleCommentsLength}
                                    handleLike = {handleLike}
                                    isActive = {isActive}
                                />
                            )
                        })
                    }
                </>
                
            )

        }

    }

    const handleLike = async(data) => {
        // if(Number(data.user.id) !== Number(userId)){
            likePosts(userId, data.id)
        // }
    }

    const handleCommentsLength = (comments) =>{
        if(comments && comments.length > 0){
            const validComments = comments.filter((item , index)=>{
                if(item.status === '1'){
                    if(item.comment_id === null){
                        return item
                    }else{
                        const reply = comments.find((c) => Number(c.id) === Number(item.comment_id));
                        if(reply && reply.status === '1'){
                            return item
                        }
                    }
                }
            });
            if(validComments && validComments.length > 0){
                return (
                    <small className='ms-2'>{validComments.length}</small>
                )
            }

        }
    }

    const handleChangePostStatus = (postId , status) =>{
        const data = {postId:postId , status:status}
        setLoading(true)
        dispatch(changePostStatus(data))
        
    }

    useEffect(()=>{
        if(postStatus && postStatus.success){
            toast.success(postStatus.message, {
                position: 'top-center', 
            });
            if( postStatus.message.trim() === 'Post deleted'){
                navigate('/')
            }else{
                navigate('/drafts')
            }
            setLoading(true)
            // dispatch(getSingleBoardPostList(slug));
        }
    },[postStatus])

    useEffect(()=>{
        if(postStatusError && postStatusError.message){
            toast.error(apiError.textPostError.message, {
                position: 'top-center', 
            });
        }
    },[postStatusError])

    const copyText = () =>{
        toast.success('Link copy to clipboard', {position: 'top-center'});
    }
/******************************************************************************************** */    

 
    return (
        <>
            <PageHeader />
            <div className='page--content'>
                <SidebarMenu />
                <div className='discover--module common--wrapper product--category--page video--main--page'>
                    <Container>
                    {!loading ?
                        <>
                            <Row>
                                <Col sm={12}>
                                    <div className='product--banner'>
                                        <img src={(realmCat && realmCat.thumbnail) ? realmCat.thumbnail : '/images/background.png'} alt='...' />
                                        <h1>{(realmCat && realmCat.name)? realmCat.name : ''}</h1>
                                    </div>
                                </Col>
                            </Row>
                            <ListGroup as="ul" horizontal className='mb-3'>
                                <ListGroup.Item as="li" className={isActive ? 'inactive' : 'active'}  onClick={handleToggle}><BsGridFill /></ListGroup.Item>
                                <ListGroup.Item as="li" className={isActive ? 'active' : 'inactive'}   onClick={handleToggle}><BsCardList /></ListGroup.Item>
                            </ListGroup>
                            <Row className={isActive ? 'justify-content-start grid--view disclosure_wrapp' : 'justify-content-center list--view disclosure_wrap'}>
                                { (realmPosts && realmPosts.length > 0) 
                                    ?   <>
                                            {filterPosts()}
                                        </>
                                    :    <h2> Posts Not Found </h2>

                                }
                            </Row>
                        </>    
                    :
                        <Row>
                            <Col sm={12}>
                                <div className='text-center spinner--loader'></div>
                            </Col>
                        </Row>

                    }    

                    </Container>
                </div>
            </div>
            <Toaster/>
        </>
    );
}

export default RealmDisclosure;